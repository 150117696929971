import React from "react";

import { Box, Flex, Heading, Skeleton } from "@atoms";
import { Article } from "@models";
import { ArticleCard } from "@molecules";

interface IArticlesSectionProps {
  articles: ReturnType<typeof Article>[];
  title: string;
}

const ArticlesSection = ({ articles, title }: IArticlesSectionProps) => {
  return (
    <>
      <Box
        as="hr"
        my={3}
        borderTop={1}
        borderTopColor="borderColor"
        opacity={0.6}
      />

      <Box>
        <Heading
          textAlign="center"
          fontSize={6}
          textTransform="none"
          opacity="0.9"
          my={[2]}
        >
          {title}
        </Heading>

        <Flex py={3} flexDirection={["column"]}>
          {articles?.map((article) => {
            return (
              <Box pb={3} key={article.id}>
                <ArticleCard article={article} />
              </Box>
            );
          })}
        </Flex>
      </Box>
    </>
  );
};

ArticlesSection.Skeleton = () => (
  <Box mt={4}>
    <Box
      as="hr"
      my={3}
      borderTop={1}
      borderTopColor="borderColor"
      opacity={0.6}
    />

    <Flex width="100%" justifyContent="center">
      <Skeleton width={250} height={25} />
    </Flex>

    <Flex py={3} flexDirection={["column"]}>
      <Box pb={3}>
        <ArticleCard.Skeleton />
        <ArticleCard.Skeleton />
        <ArticleCard.Skeleton />
      </Box>
    </Flex>
  </Box>
);

export default ArticlesSection;
