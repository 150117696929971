import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { StorageUtil } from "@utils";
import { LocalStorageWrapper, persistCacheSync } from "apollo3-cache-persist";
import fetch from "cross-fetch";

let client: ApolloClient<NormalizedCacheObject> | null = null;

const initCache = (initialState?: any) => {
  const cache = new InMemoryCache().restore(initialState || {});

  if (typeof window !== "undefined") {
    persistCacheSync({
      cache,
      storage: new LocalStorageWrapper(StorageUtil()),
      debug: process.env.NODE_ENV === "development",
      trigger: "write",
    });
  }

  return cache;
};

export const getClient = () => {
  if (client) {
    return client;
  }

  client = new ApolloClient({
    cache: initCache(),
    link: new HttpLink({
      uri: process.env.GATSBY_GRAPHQL_SERVER,
      fetch,
    }),
    connectToDevTools: process.env.NODE_ENV === "development",
  });

  return client;
};
