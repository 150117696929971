import React from "react";

import { Text, Flex, Paragraph, Skeleton, Box } from "@atoms";

interface IVerseCardProps {
  body: string;
  verse: string;
}

const VerseCard = ({ body, verse }: IVerseCardProps) => {
  return (
    <Flex bg="foreground" flexDirection="column" height="auto" p={3}>
      <Text fontWeight={5} textAlign="right" fontFamily="medium">
        Versículo del día
      </Text>

      <Paragraph textAlign="right" lineHeight="solid" py={[2]}>
        {`"${body}"`}
      </Paragraph>

      <Text
        color="accent"
        fontSize={12}
        fontWeight={5}
        textTransform="uppercase"
        textAlign="right"
      >
        {verse}
      </Text>
    </Flex>
  );
};

VerseCard.Skeleton = () => (
  <Flex
    bg="foreground"
    alignItems="end"
    flexDirection="column"
    width="100%"
    p={3}
  >
    <Box width="60%" my={1}>
      <Skeleton height={15} />
    </Box>
    <Box width="90%">
      <Skeleton height={10} count={3} />
    </Box>
    <Box width="40%">
      <Skeleton height={10} />
    </Box>
  </Flex>
);

export default VerseCard;
