import {
  OnChangeEventType,
  OnClickEventType,
  OnSelectChangeEventType,
} from "@interfaces";

export const callOnChange =
  (fn: (s: string) => void) => (e: OnChangeEventType) => {
    e.stopPropagation();
    fn(e.currentTarget.value);
  };

export const onSelectChange = (fn: (s: string) => void) => {
  return (e: OnSelectChangeEventType) => {
    e.stopPropagation();
    fn(e.target.value);
  };
};

export const onClickHandler =
  <T, U = void>(fn: (...args: T[]) => U, ...args: T[]) =>
  (e: OnClickEventType) => {
    e.stopPropagation();
    e.preventDefault();
    fn(...args);
  };
