import React from "react";
import { StyledUtil } from "@utils";

import { IStyledTextProps } from "@interfaces";

const Text = StyledUtil.styled("span", {
  display: "block",
  color: "textColor",
  fontFamily: "regular",
  fontSize: 3,
  lineHeight: "copy",
});

export default Text as React.FC<IStyledTextProps>;
