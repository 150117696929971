import { Box } from "@atoms";
import { ResponsiveUtil } from "@utils";
import React from "react";
import SocialButtons from "../socialButtons";

interface IProps extends React.ComponentProps<typeof SocialButtons> {}

const FloatSocialButtons = ({ ...props }: IProps) => {
  return (
    <Box
      position="absolute"
      right={ResponsiveUtil.obj2arr({
        sm: "0",
        uhd: "-5em",
      })}
      top={0}
      height="100%"
    >
      <SocialButtons
        {...props}
        direction="vertical"
        display={ResponsiveUtil.obj2arr({
          sm: "none",
          xl: "flex",
        })}
        position="sticky"
        top={0}
        right={0}
        paddingTop={5}
        paddingLeft={ResponsiveUtil.obj2arr({
          uhd: 3,
        })}
        iconSize={40}
      />
    </Box>
  );
};

FloatSocialButtons.defaultProps = {
  title: "",
  hashtag: "",
};

export default FloatSocialButtons;
