import React from "react";

const useWindowSize = () => {
  const [{ innerWidth, innerHeight }, setSize] = React.useState({
    innerWidth: 0,
    innerHeight: 0,
  });

  React.useLayoutEffect(() => {
    function update() {
      setSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }

    window.addEventListener("resize", update);
    update();
    return () => window.removeEventListener("resize", update);
  }, []);

  return { innerWidth, innerHeight };
};

export default useWindowSize;
