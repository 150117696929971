import { StyledUtil } from "@utils";
import { variant as variantFn } from "styled-system";

const Badge = StyledUtil.styled(
  "span",
  {
    maxWidth: "100%",
    height: "auto",
    backgroundColor: "orangeWhite",
    color: "white",
    fontFamily: "bold",
    fontSize: 3,
  },
  variantFn({
    prop: "size",
    variants: {
      small: {
        paddingY: 1,
        paddingX: 3,
        fontSize: 2,
      },
      medium: {
        paddingY: 2,
        paddingX: 3,
        fontSize: 3,
      },
    },
  }),
);

Badge.defaultProps = {
  size: "small",
};

export default Badge;
