import React, { AnchorHTMLAttributes } from "react";

import { Mixins, Routes } from "@core";
import { DateUtil, MediaUtil, ResponsiveUtil, StringUtil } from "@utils";
import {
  Badge,
  Box,
  Flex,
  Heading,
  Link,
  LinkRouter,
  Paragraph,
  Skeleton,
  Tag,
  Text,
} from "@atoms";
import { Article } from "@models";
import Avatar from "../avatar";
import ImageLazy from "../imageLazy";

interface IArticleRecentCardProps {
  article: NonNullable<ReturnType<typeof Article>>;
}

const Anchor = ({
  href,
  children,
}: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  if (!href || href?.indexOf("https") !== -1) {
    return (
      <Link target="_blank" href={href} color="accent">
        {children}
      </Link>
    );
  }

  return (
    <LinkRouter to={href} color="accent">
      {children}
    </LinkRouter>
  );
};

const ArticleRecentCard = ({ article }: IArticleRecentCardProps) => {
  const dateFormat = DateUtil(article.publishedAt).format(
    "DD [de] MMMM [de] YYYY",
  );
  const linkTo = Routes.ARTICLE_DETAIL.replace(":slug", `${article.slug}`);

  return (
    <Flex
      width="100%"
      pt={3}
      pb={1}
      pr={ResponsiveUtil.obj2arr({ lg: 20 })}
      flexDirection="column"
    >
      <ImageLazy
        width="100%"
        height={[
          Mixins.px2em("180px"),
          Mixins.px2em("280px"),
          Mixins.px2em("290px"),
          Mixins.px2em("400px"),
        ]}
        objectFit="cover"
        alt="article-relevant"
        preLoadFormat="thumbnail"
        loadFormat="medium"
        image={article?.banner}
      />

      <Flex
        mt="-1.6rem"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        zIndex={10}
      >
        <Box>
          <Badge size={["medium", "medium"]}>Artículo reciente</Badge>
        </Box>
      </Flex>

      <Heading mt={3} mb={2} fontSize={6} textTransform="none">
        <LinkRouter to={linkTo}>{article.title}</LinkRouter>
      </Heading>
      <Flex flexDirection="row">
        {article.authors?.map((author) => (
          <LinkRouter
            key={author?.id}
            to={Routes.AUTHOR_DETAIL.replace(":username", author.slug)}
          >
            <Flex justifyContent="center" alignItems="center" mr={3}>
              <Avatar
                src={MediaUtil.getFormatUrl(
                  author.avatar,
                  "thumbnail",
                  "small",
                )}
                size="xsmall"
                alt={author?.fullname}
                mr={2}
              />
              <Text fontFamily="regular">{author.fullname}</Text>
            </Flex>
          </LinkRouter>
        ))}
      </Flex>
      <Text mt={1} opacity="0.8" fontSize={2}>
        {dateFormat} • {`${article.readingTime} min`}
      </Text>
      <Paragraph
        fontSize={3}
        mt={[1]}
        lineHeight="copy"
        components={{
          a: Anchor,
        }}
      >
        {`${StringUtil.toExcerpt(article.body)}. [Seguir leyendo.](${linkTo})`}
      </Paragraph>
      <Flex ml="-0.5rem" my={1} flexDirection="row" flexWrap="wrap">
        {article?.tags.map((tag) => (
          <Tag
            key={`Tag-${tag.id}`}
            my={1}
            title={tag?.name}
            color="borderColorSecondary"
          />
        ))}
      </Flex>
    </Flex>
  );
};

ArticleRecentCard.Skeleton = () => (
  <Flex
    width="100%"
    py={3}
    pr={ResponsiveUtil.obj2arr({ lg: 20 })}
    flexDirection="column"
  >
    <Box
      height={[
        Mixins.px2em("180px"),
        Mixins.px2em("280px"),
        Mixins.px2em("290px"),
        Mixins.px2em("400px"),
      ]}
    >
      <Skeleton height="100%" />
    </Box>

    <Skeleton my={2} width="30%" height={25} />

    <Flex alignItems="center">
      <Skeleton height={25} width={25} circle inline mr={2} />
      <Skeleton height={25} width={150} inline />
    </Flex>

    <Skeleton my={2} height={20} width="45%" />

    <Skeleton mb={2} height={15} count={4} />
  </Flex>
);

export default ArticleRecentCard;
