module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"es_DO","url":"https://porcausadelevangelio.com","site_name":"Por Causa del Evangelio","title":"Por Causa del Evangelio","images":[{"url":"https://porcausadelevangelio.com/banner.png","width":1181,"height":821,"alt":"Banner"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Por Causa del Evangelio","short_name":"PCEVG","icon":"./src/assets/favicon/favicon-32x32.png","icons":[{"src":"./src/assets/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./src/assets/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color":"#ffffff","background_color":"#ffffff","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6177d774a355f708bac8d18c0b44afa5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-X1RGZFE189"],"pluginConfig":{"head":true,"respectDNT":false,"delayOnRouteUpdate":8000}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#0B87D1","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
