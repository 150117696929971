import { ITagGQL } from "@interfaces";

const Tag = ({ id, attributes }: ITagGQL) => {
  return {
    id,
    name: attributes?.name,
    createdAt: attributes?.createdAt,
  };
};

export default Tag;
