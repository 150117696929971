import React from "react";

import { Mixins, Routes } from "@core";
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  Paragraph,
  Tag,
  LinkRouter,
  Skeleton,
} from "@atoms";
import { DateUtil, MediaUtil, ResponsiveUtil } from "@utils";
import { Article } from "@models";

interface IArticleCardProps {
  article: ReturnType<typeof Article>;
  excerptLength?: number;
  hideTags?: boolean;
  hideBody?: boolean;
  hideImage?: boolean;
}

const ArticleCard = ({
  article,
  excerptLength,
  hideTags,
  hideBody,
  hideImage,
}: IArticleCardProps) => {
  const {
    authors = [],
    banner,
    title = "",
    slug = "",
    publishedAt: date = "",
    body = "",
    tags = [],
  } = article ?? {};

  const authorsName = React.useMemo(() => {
    return authors
      ?.map?.(({ fullname }) => {
        return fullname;
      })
      .join(", ");
  }, [authors]);

  const dateFormat = DateUtil(date).format("DD MMMM, YYYY");

  return (
    <Flex width="100%">
      {banner && !hideImage ? (
        <Box width="30%">
          <LinkRouter to={Routes.ARTICLE_DETAIL.replace(":slug", `${slug}`)}>
            <Image
              src={MediaUtil.getFormatUrl(banner!, "small", "thumbnail")}
              alt={title}
              width="100%"
              height="100%"
              minHeight={[Mixins.px2rem("95px")]}
              maxHeight={[
                Mixins.px2rem("110px"),
                Mixins.px2rem("120px"),
                Mixins.px2rem("130px"),
              ]}
              maxWidth={[Mixins.px2rem("241px")]}
              objectFit="cover"
            />
          </LinkRouter>
        </Box>
      ) : null}

      <Flex
        width={!hideImage ? "70%" : "100%"}
        pl={!hideImage ? 3 : 0}
        flexDirection="column"
      >
        <Text fontSize={[2, null, 2]} opacity="0.6">
          {`${dateFormat}`}
        </Text>
        <Heading
          fontSize={[3, 4, 5]}
          opacity="0.9"
          textTransform="none"
          mb={ResponsiveUtil.obj2arr({
            lg: 1,
          })}
        >
          <LinkRouter to={Routes.ARTICLE_DETAIL.replace(":slug", `${slug}`)}>
            {title}
          </LinkRouter>
        </Heading>

        <Text pt={1} fontSize={[2]} opacity="0.8">
          {authorsName || "‎"}
        </Text>

        {!hideBody && body && (
          <Paragraph fontSize={[2, 3, 2]} opacity="0.8" my={1}>
            {`${body.substring(0, excerptLength)}...`}
          </Paragraph>
        )}

        {!hideTags && tags && tags.length > 0 && (
          <Flex
            flex={1}
            display={[!authorsName ? "flex" : "none", null, "flex"]}
            alignItems="flex-end"
            ml="-0.5rem"
            mt={2}
            flexDirection="row"
            flexWrap="wrap"
          >
            {tags?.slice(0, 3).map((tag) => (
              <Tag
                key={tag.id}
                color="borderColorSecondary"
                title={tag.name}
                textTransform="capitalize"
              />
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

ArticleCard.defaultProps = {
  excerptLength: 40,
  hideImage: false,
  hideTags: false,
  hideBody: true,
};

ArticleCard.Skeleton = () => (
  <Flex mb={3} pr={[0, 3]} width="100%">
    <Box
      width="30%"
      height="100%"
      maxHeight={[Mixins.px2rem("108px"), Mixins.px2rem("120px")]}
      maxWidth={[Mixins.px2rem("241px")]}
    >
      <Skeleton width="100%" height={110} />
    </Box>

    <Flex width="70%" pl={2} flexDirection="column" mt={2}>
      <Skeleton width="40%" height={15} mb={2} />
      <Skeleton my={1} height={20} />
      <Skeleton width="60%" height={10} />

      <Flex flexDirection="row" mt={2}>
        <Skeleton width={50} height={28} count={3} mr={2} inline />
      </Flex>
    </Flex>
  </Flex>
);

export default ArticleCard;
