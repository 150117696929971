import useLocation from "./useLocation";

const useQueryParams = <T extends { [key: string]: string }>() => {
  const location = useLocation();
  const search = location.search.substring(1);

  const params = search.split("&");
  if (!params?.length) {
    return {} as T;
  }

  return params.reduce((acc, param) => {
    const [key, value] = param.split("=");
    if (!value) {
      return acc;
    }

    return {
      ...acc,
      [key]: decodeURIComponent(value),
    };
  }, {}) as T;
};

export default useQueryParams;
