import React from "react";

import {
  Flex,
  Heading,
  Image,
  Text,
  Button,
  Skeleton,
  Link,
  Box,
} from "@atoms";
import { Resource } from "@models";
import { Mixins } from "@core";
import { MediaUtil } from "@utils";

interface IResourceProps {
  resource: ReturnType<typeof Resource>;
}

const ResourceCard = ({ resource }: IResourceProps) => {
  const { title = "", description = "", author = "", banner } = resource ?? {};

  const renderButtonTitle = React.useMemo(() => {
    if (!resource?.link) {
      return "Ver";
    }

    if (/(amazon\.com|amzn\.to|a.co|ebay)/i.test(resource?.link)) {
      return "Comprar";
    }

    if (/(.pdf|.ppt)/i.test(resource?.link)) {
      return "Descargar";
    }

    if (/(youtube\.com)/i.test(resource?.link)) {
      return "Ir a Youtube";
    }

    return "Ver";
  }, [resource]);

  return (
    <Flex flexDirection="column" mb={4} px={2} width={Mixins.px2rem("300px")}>
      <Image
        objectFit="contain"
        maxHeight={[Mixins.px2rem("300px"), null, Mixins.px2rem("200px")]}
        src={banner ? MediaUtil.getFormatUrl(banner, "small") : ""}
        alt={`resource-${title}`}
        mb={3}
      />

      <Heading textTransform="none" fontSize={5} color="primaryDark">
        {title}
      </Heading>

      <Text color="primaryDark" fontWeight={6} fontSize={3} py={1}>
        {author}
      </Text>

      <Text color="primaryDark" opacity="0.8" fontSize={2} mb={2}>
        {description}
      </Text>

      <Box flex={1} display="flex" alignItems="flex-end" pt={[2]}>
        <Link href={resource?.link} target="_blank" width="100%">
          <Button color="primaryDark" shape="square" width="100%">
            {renderButtonTitle}
          </Button>
        </Link>
      </Box>
    </Flex>
  );
};

ResourceCard.Skeleton = () => (
  <Flex justifyContent="center" width={["100%", "50%", "33.3%"]}>
    <Flex flexDirection="column" mb={4} px={2} width={Mixins.px2rem("300px")}>
      <Skeleton mb={3} height={200} />

      <Skeleton mb={2} height={20} />

      <Skeleton py={1} height={15} width="45%" />

      <Skeleton mb={1} height={15} width="90%" />
      <Skeleton mb={2} height={15} width="60%" />
      <Skeleton mb={2} height={15} width="80%" />
      <Skeleton mb={2} height={15} width="50%" />

      <Skeleton mt={2} height={45} width="100%" />
    </Flex>
  </Flex>
);

export default ResourceCard;
