import { Box, Flex, Heading, Skeleton } from "@atoms";
import { Category } from "@models";
import { ArticleCard } from "@molecules";
import React from "react";

const HomeCategorySection = ({
  category,
}: {
  category: ReturnType<typeof Category.Articles>;
}) => {
  return (
    <Box width={["100%", "50%", "25%"]} px={3} pt={3}>
      <Heading
        fontSize={4}
        color="secondary"
        width="100%"
        borderBottom="2"
        borderBottomColor="secondary"
        textTransform="none"
      >
        {category.name}
      </Heading>
      <Flex flexDirection="column">
        {category?.articles?.map((article) => (
          <Box key={`Article-Category-${article?.id}`} py={2} width="100%">
            <ArticleCard article={article} hideImage hideTags />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

const ArticleSkeleton = () => {
  return (
    <Flex width="70%" flexDirection="column" mt={2}>
      <Skeleton width="40%" height={15} mb={2} />
      <Skeleton width="80%" my={1} height={20} />
      <Skeleton width="50%" height={10} />
    </Flex>
  );
};

HomeCategorySection.Skeleton = () => {
  return (
    <Box width={["50%", null, "25%"]} px={3} pt={3}>
      <Skeleton height={20} />
      <Flex flexDirection="column" mt={2}>
        <ArticleSkeleton />
        <ArticleSkeleton />
      </Flex>
    </Box>
  );
};

export default HomeCategorySection;
