import React from "react";
import { StyledUtil } from "@utils";

import { IStyledBoxProps } from "@interfaces";

import Box from "../box";

const Flex = StyledUtil.styledAnyComponent(Box, {
  display: "flex",
});

export default Flex as React.FC<IStyledBoxProps>;
