import { StorageUtil, DateUtil } from "@utils";
import {
  FetchPolicy,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from "@apollo/client";
import { Constants } from "@core";

const LocalStorage = StorageUtil();

const getExpirationFetchPolicy = (
  expiration: number,
  key: string,
): FetchPolicy => {
  const lastFetch = LocalStorage.getItem(key);

  if (
    lastFetch === null ||
    DateUtil().diff(DateUtil(lastFetch), "second") > expiration
  ) {
    LocalStorage.setItem(
      key,
      DateUtil().set("second", expiration).toISOString(),
    );
    return "network-only";
  }

  return "cache-first";
};

/**
 * This is a function to help to create a expiration date for useQuery.
 * @param query Query
 * @param expiration Expiration value in seconds.
 * @param key Key for storage
 * @param options Query hook options
 * @returns Query results
 */
const useQueryExpiration = <
  T,
  U extends OperationVariables = OperationVariables,
>(
  query: any,
  expiration: keyof typeof Constants.CACHE_TIME,
  key: string,
  options?: Omit<QueryHookOptions<T, U>, "fetchPolicy" | "nextFetchPolicy">,
) => {
  const exp = Constants.CACHE_TIME[expiration] || Constants.CACHE_TIME.SHORT;
  const fetchPolicy = getExpirationFetchPolicy(exp, key);
  return useQuery<T, U>(query, {
    ...options,
    fetchPolicy,
  });
};

export default useQueryExpiration;
