import React from "react";

import { Author } from "@models";
import { Routes } from "@core";
import { Box, Flex, Heading, LinkRouter, Text, Skeleton } from "@atoms";

import { MediaUtil } from "@utils";
import Avatar from "../avatar";

interface IAuthorItemProps {
  author: ReturnType<typeof Author>;
}

const AuthorItem = ({ author }: IAuthorItemProps) => {
  return (
    <Flex alignItems="center" mt={2}>
      <LinkRouter to={Routes.AUTHOR_DETAIL.replace(":username", author.slug)}>
        <Avatar
          src={MediaUtil.getFormatUrl(author?.avatar, "small")}
          alt={author?.avatar.name}
          size="small"
          objectFit="cover"
          style={{ objectPosition: "top" }}
        />
      </LinkRouter>

      <Flex pl={3} flexDirection="column" justifyContent="center">
        <LinkRouter to={Routes.AUTHOR_DETAIL.replace(":username", author.slug)}>
          <Heading fontSize={3} opacity="0.9" textTransform="capitalize">
            {author.fullname}
          </Heading>
          <Text fontSize={2} mr={1} opacity="0.8">
            {author.role}
          </Text>
        </LinkRouter>
      </Flex>
    </Flex>
  );
};

AuthorItem.Skeleton = () => (
  <Flex width="100%" alignItems="center" mb={2}>
    <Box>
      <Skeleton height={80} width={80} circle inline />
    </Box>

    <Flex width="65%" pl={3} flexDirection="column" justifyContent="center">
      <Skeleton height={15} width={100} mb={2} />
      <Skeleton height={10} width={60} />
    </Flex>
  </Flex>
);

export default AuthorItem;
