import {
  Box,
  Container,
  Flex,
  Image,
  Paragraph,
  Skeleton,
  SocialButton,
  Text,
} from "@atoms";
import { Constants, Routes } from "@core";
import { MenuLink, SubscribeBox } from "@molecules";
import { JSXUtil, ResponsiveUtil } from "@utils";
import React from "react";
import { Queries } from "@graphql-client";
import { useQueryExpiration } from "@hooks";
import { ISocialNetworkGQL } from "@interfaces";

interface IFooterData {
  footer: {
    data: {
      id: string;
      attributes: {
        description: string;
        socialNetworks: ISocialNetworkGQL[];
      };
    };
  };
}

const Footer = () => {
  const { data, loading } = useQueryExpiration<IFooterData, {}>(
    Queries.GET_FOOTER,
    "LONG",
    Constants.CACHE_KEYS.FOOTER,
  );

  return (
    <Box backgroundColor="primary">
      <Container
        variant="layout"
        py={[4, null, null, 5]}
        display="flex"
        flexDirection={["column", null, "row"]}
        justifyContent={ResponsiveUtil.obj2arr({
          lg: "space-between",
        })}
      >
        <Flex
          flexDirection="column"
          alignItems={["center", null, "flex-start"]}
          justifyContent={ResponsiveUtil.obj2arr({
            lg: "space-between",
          })}
          width={["100%", null, "50%", "40%"]}
        >
          <Box width={150} height={50}>
            <Image src="/logo.svg" height="100%" alt="logo" />
          </Box>
          <Paragraph
            color="white"
            my={3}
            textAlign={["center", null, "initial"]}
          >
            {data?.footer?.data?.attributes?.description ?? ""}
          </Paragraph>
          <Box>
            <MenuLink
              title="Conócenos"
              to={Routes.ABOUT}
              color="white"
              buttonProps={{
                px: 3,
                type: "outline",
                color: "white",
              }}
            />
          </Box>
        </Flex>
        <Flex
          width={["100%", null, "50%", "40%"]}
          flexDirection="column"
          mt={[5, null, 0]}
          alignItems={["center", null, "flex-end"]}
        >
          <Flex>
            {JSXUtil.renderLoader(
              loading,
              <Flex>
                <Skeleton height={35} width={35} circle inline />
                <Skeleton height={35} width={35} circle inline mx={2} />
              </Flex>,
            )(
              data?.footer?.data?.attributes?.socialNetworks?.map(
                (item: any) => (
                  <SocialButton
                    key={`social-button-${item.id}`}
                    target="_blank"
                    name={item?.socialNetwork.toLowerCase()}
                    href={item?.url}
                  />
                ),
              ),
            )}
          </Flex>
          <Box width={["85%"]}>
            <Text
              color="white"
              mt={3}
              mb={[1, null, 2]}
              textAlign={["center", null, "right"]}
            >
              Recibe en tu correo las últimas publicaciones.
            </Text>
            <SubscribeBox />
          </Box>
          <Box pt={4}>
            <Text textAlign={["center", null, "right"]} color="white">
              © 2023 Por causa del Evangelio.
            </Text>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default React.memo(Footer);
