import React from "react";
import { Constants, Mixins } from "@core";
import { StyledUtil } from "@utils";
import { variant } from "styled-system";

import { IStyledBoxProps } from "@interfaces";
import Box from "../box";

interface IProps extends IStyledBoxProps {
  variant: "compacted" | "blog" | "layout";
}

const Container = StyledUtil.styledAnyComponent(
  Box,
  {
    m: [null, null, "0 auto"],
    paddingX: Constants.CONTAINER_PADDING_X_ARRAY,
  },
  variant({
    variants: {
      compacted: {
        maxWidth: [null, null, null, Mixins.px2rem("881px")],
      },
      blog: {
        maxWidth: [null, null, null, Mixins.px2rem("900px")],
      },
      layout: {
        maxWidth: [null, null, null, Mixins.px2rem("1616px")],
      },
    },
  }),
);

export default Container as React.FC<IProps>;
