import React from "react";
import { LazyQueryHookOptions, useLazyQuery } from "@apollo/client";

import { Queries } from "@graphql-client";
import { IArticleResponseGQL, IArticlesTVars } from "@interfaces";
import { Article } from "@models";

interface IArticlesData {
  articles: IArticleResponseGQL;
}

const PAGE_SIZE = 10;

const useSearch = (
  options?: LazyQueryHookOptions<IArticlesData, IArticlesTVars>,
) => {
  const [articles, setArticles] = React.useState<ReturnType<typeof Article>[]>(
    [],
  );
  const [isLoadingMore, setLoadingMore] = React.useState(false);
  const [getArticles, { data, fetchMore, ...rest }] = useLazyQuery<
    IArticlesData,
    IArticlesTVars
  >(Queries.GET_ARTICLES, {
    fetchPolicy: "cache-and-network",
    ...options,
    variables: {
      ...options?.variables,
      pagination: {
        page: 1,
        pageSize: PAGE_SIZE,
      },
    },
    onCompleted: (results) => {
      setLoadingMore(false);
      setArticles(results?.articles?.data?.map(Article) ?? []);
    },
  });

  const fnFetchMore = React.useCallback(() => {
    setLoadingMore(true);
    fetchMore({
      variables: {
        pagination: {
          page: (data?.articles?.meta?.pagination?.page ?? 0) + 1,
          pageSize: PAGE_SIZE,
        },
      },
      updateQuery: (prevData, { fetchMoreResult }) => {
        return {
          articles: {
            data: [
              ...prevData.articles.data,
              ...(fetchMoreResult?.articles?.data ?? []),
            ],
            meta: {
              ...prevData.articles.meta,
              ...(fetchMoreResult?.articles?.meta ?? {}),
            },
          },
        };
      },
    });
  }, [data]);

  return {
    getArticles,
    fetchMore: fnFetchMore,
    isLoadingMore,
    ...rest,
    articles,
    meta: data?.articles?.meta,
  };
};

export default useSearch;
