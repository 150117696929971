import React from "react";

import { Navbar, Footer } from "@organisms";
import { Box } from "@atoms";
import { useWindowDimensions } from "@hooks";
import { BrowserUtil } from "@utils";

const Layout: React.FC<{}> = ({ children }) => {
  const { height } = useWindowDimensions();
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    if (BrowserUtil.isBrowser()) {
      setIsClient(true);
    }
  }, []);

  return (
    <React.Fragment key={isClient ? "render-client" : "render-server"}>
      <Navbar />
      <Box minHeight={height * 0.8}>{children}</Box>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
