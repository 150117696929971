import { TWithContext, ThingSchema } from "@interfaces";
import React from "react";
import { Helmet } from "react-helmet-async";

interface IProps<T extends ThingSchema> {
  json: TWithContext<T>;
}

const isDev = process.env.NODE_ENV === "development";

const JSONLD = <T extends ThingSchema>({ json, ...props }: IProps<T>) => (
  <Helmet {...props}>
    <script type="application/ld+json">
      {JSON.stringify(json, null, isDev ? 2 : 0)}
    </script>
  </Helmet>
);

export default JSONLD;
