import React from "react";

import { ResponsiveStyleValue } from "@styled-system/css";

import { Flex, LinkRouter, Text } from "@atoms";
import { Routes } from "@core";

import Avatar from "../avatar";

interface ITeamCardProps {
  fullname: string;
  avatar: string;
  role: string;
  slug: string;
  size: ResponsiveStyleValue<"small" | "medium" | "large">;
}

const TeamCard: React.FC<ITeamCardProps> = ({
  fullname,
  avatar,
  size,
  role,
  slug,
}) => (
  <Flex flexDirection="column" alignItems="center">
    <LinkRouter to={Routes.AUTHOR_DETAIL.replace(":username", slug)}>
      <Avatar
        size={size}
        objectFit="cover"
        style={{ objectPosition: "top" }}
        mb={2}
        src={avatar}
        alt={`team-${fullname}`}
      />
    </LinkRouter>

    <LinkRouter
      to={Routes.AUTHOR_DETAIL.replace(":username", slug)}
      fontSize={[4]}
      fontFamily="bold"
      py={[1]}
      textAlign="center"
    >
      {fullname}
    </LinkRouter>
    <Text color="primary" fontSize={[2]} textAlign="center">
      {role}
    </Text>
  </Flex>
);

export default TeamCard;
