/* eslint-disable @typescript-eslint/no-explicit-any */
import * as StyledSystem from "styled-system";
import * as ForwardPropHandler from "@styled-system/should-forward-prop";
import styledComponents from "styled-components";
import css, { SystemCssProperties } from "@styled-system/css";

import { StyledExtendedStyleProps } from "@interfaces";

const extendedCssProperties = StyledSystem.system({
  outline: true,
  transform: true,
  textDecoration: true,
  textTransform: true,
  cursor: true,
  objectFit: true,
  visibility: true,
  disabled: true,
  overflow: true,
});

export const getStyledComposition = StyledSystem.compose(
  extendedCssProperties,
  StyledSystem.space,
  StyledSystem.color,
  StyledSystem.layout,
  StyledSystem.typography,
  StyledSystem.flexbox,
  StyledSystem.border,
  StyledSystem.background,
  StyledSystem.position,
  StyledSystem.grid,
  StyledSystem.shadow,
  StyledSystem.width,
  StyledSystem.minWidth,
  StyledSystem.height,
  StyledSystem.minHeight,
);

const shouldForwardProp = ForwardPropHandler.createShouldForwardProp([
  ...ForwardPropHandler.props,
  // Add new custom prop used in the components (eg. size, shape, etc.);
  "size",
  "shape",
  "textDecoration",
  "transform",
  "visibility",
  "overflow",
]);

export const styledbase = (component: any) => {
  return styledComponents(component).withConfig({
    shouldForwardProp: (prop: string | number | symbol) => {
      if (typeof prop !== "string") {
        return false;
      }

      return shouldForwardProp(prop);
    },
  });
};

export const styled = (
  tag: string,
  extendedStyle: StyledExtendedStyleProps,
  ...props: any[]
) => {
  return styledbase(tag)(
    css(extendedStyle as SystemCssProperties),
    getStyledComposition,
    props,
  );
};

export const styledAnyComponent = (
  component: any,
  extendedStyle: StyledExtendedStyleProps,
  ...props: any[]
) => {
  return styledComponents(component)(
    css(extendedStyle as SystemCssProperties),
    getStyledComposition,
    ...props,
  );
};
