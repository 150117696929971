import React, { KeyboardEvent } from "react";

import { StyledUtil } from "@utils";
import { IInputEvents, IStyledTextProps } from "@interfaces";

import { Mixins } from "@core";
import css, { SystemStyleObject } from "@styled-system/css";

const InputBase = StyledUtil.styled("input", {
  display: "block",
  border: 0,
  fontFamily: "regular",
  borderRadius: 1,
  fontSize: 2,
  height: Mixins.px2rem("42px"),
  outline: "none",
});

type IProps = React.ComponentProps<typeof InputBase> &
  IStyledTextProps &
  IInputEvents & {
    name: string;
    type?: "text" | "textarea";
    placeholder: string;
    placeholderProps?: SystemStyleObject;
    onSubmit?: () => void;
  };

const Input: React.FC<IProps> = ({
  placeholderProps,
  onSubmit,
  onKeyDown,
  value,
  ...props
}) => {
  const onKeyDownHandler = React.useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        onSubmit?.();
        return;
      }

      onKeyDown?.(e);
    },
    [value],
  );

  return (
    <InputBase
      {...props}
      onKeyDown={onKeyDownHandler}
      value={value}
      css={css({
        "&::placeholder": placeholderProps!,
        "&:focus": {
          outline: "none",
        },
      })}
    />
  );
};

Input.defaultProps = {
  type: "text",
  placeholderProps: {},
  onSubmit: () => {},
};

export default Input;
