import React from "react";

import { ICategoryGQL } from "@interfaces";
import { Queries } from "@graphql-client";
import { Constants } from "@core";

import { Category, ICategory } from "@models";
import useQueryExpiration from "./useQueryExpiration";

interface ICategoriesQueryData {
  categories: {
    data: ICategoryGQL[];
  };
}

const useGetCategoriesQuery = () => {
  const { data, ...rest } = useQueryExpiration<ICategoriesQueryData>(
    Queries.GET_CATEGORIES,
    "LONG",
    Constants.CACHE_KEYS.CATEGORIES,
  );

  return {
    ...rest,
    categories: React.useMemo((): ICategory[] => {
      const payload = data?.categories?.data;
      if (!payload?.length) {
        return [];
      }

      return payload.reduce((acc, category) => {
        if (!category) {
          return acc;
        }

        return [...acc, Category(category)];
      }, [] as ICategory[]);
    }, [data]),
  };
};

export default useGetCategoriesQuery;
