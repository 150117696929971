import React from "react";

import { useQuery, QueryHookOptions } from "@apollo/client";
import { Queries } from "@graphql-client";

import { IArticleResponseGQL, IArticlesTVars } from "@interfaces";
import { Article, IArticle } from "@models";

interface IArticlesData {
  articles: IArticleResponseGQL;
}

const useGetArticlesQuery = (
  options?: QueryHookOptions<IArticlesData, IArticlesTVars>,
) => {
  const { data, ...rest } = useQuery<IArticlesData, IArticlesTVars>(
    Queries.GET_ARTICLES,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      ...options,
      variables: {
        sort: "publishedAt:desc",
        ...options?.variables,
        pagination: {
          limit: 7,
          ...options?.variables?.pagination,
        },
      },
    },
  );

  return {
    ...rest,
    articles: React.useMemo((): IArticle[] => {
      const payload = data?.articles?.data;
      if (!payload?.length) {
        return [];
      }

      return payload.reduce((acc, article) => {
        if (!article) {
          return acc;
        }

        return [...acc, Article(article)];
      }, [] as IArticle[]);
    }, [data]),
  };
};

export default useGetArticlesQuery;
