import React from "react";
import { StyledUtil } from "@utils";

import { IStyledBoxProps } from "@interfaces";

const Box = StyledUtil.styled("div", {
  boxSizing: "border-box",
  minWidth: 0,
});

export default Box as React.FC<IStyledBoxProps>;
