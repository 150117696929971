import { ReactNode } from "react";

type C = ReactNode | ReactNode[];

export const renderLoader = (loading: boolean, loaderComponent: C) => {
  return (renderComponent: C) => {
    if (loading) {
      return loaderComponent;
    }

    return renderComponent;
  };
};

export const renderIfNotEmpty = (predicate: unknown) => {
  return (c: C) => {
    if (!predicate) {
      return null;
    }

    if (Array.isArray(predicate) && !predicate?.length) {
      return null;
    }

    if (typeof predicate === "object" && !Object.keys(predicate).length) {
      return null;
    }

    return c;
  };
};
