import { IResourceGQL } from "@interfaces";

import Media from "./media";
import Tag from "./tag";

const Resource = (props: IResourceGQL) => {
  const { id, attributes } = props;
  return {
    id,
    title: attributes?.title,
    author: attributes?.author,
    link: attributes?.link,
    description: attributes?.description,
    tags: attributes?.tags?.data?.map(Tag) ?? [],
    banner: Media(attributes?.banner?.data),
  };
};

export type IResource = ReturnType<typeof Resource>;

export default Resource;
