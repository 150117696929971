import { IArticle, IAuthor, IResource } from "@models";

import {
  AboutPageSchemaWC,
  ArticleSchemaWC,
  ImageType,
  ItemListSchemaWC,
  ListItemSchema,
  OrganizationSchema,
  PersonSchema,
  PersonSchemaWC,
  WebPageSchemaWC,
  WebSiteSchemaWC,
} from "@interfaces";
import { toExcerptSEO } from "./stringUtil";
import {
  getArticleDetailRoute,
  getAuthorDetailRoute,
  getPageRoute,
} from "./navigationUtil";

const getImagesSEO = (image: ImageType) => {
  const imageFormats = Object.values(image.formats).map((img) => {
    return {
      url: img.url,
      alt: image.caption,
      width: img.width,
      height: img.height,
    };
  });

  return [
    ...imageFormats,
    {
      url: image.url,
      alt: image.caption,
      width: image.width,
      height: image.height,
    },
  ];
};

export const getArticle = (article: NonNullable<IArticle>) => {
  return {
    type: "article",
    title: `${article?.title} | Por Causa del Evangelio`,
    description: toExcerptSEO(article?.body, 200),
    url: getArticleDetailRoute(article.slug),
    article: {
      publishedTime: article.publishedAt,
      modifiedTime: article.updatedAt,
      authors: article.authors.map(({ slug }) => getAuthorDetailRoute(slug)),
      section: article.category.name,
      tags: article.tags.map(({ name }) => name),
    },
    images: getImagesSEO(article.banner),
  };
};

export const getAuthor = (author: IAuthor) => {
  return {
    title: `${author.fullname} | Equipo | Por Causa del Evangelio`,
    description: toExcerptSEO(author.bio, 200),
    url: getAuthorDetailRoute(author.slug),
    type: "profile",
    profile: {
      username: author.slug,
    },
    images: getImagesSEO(author.avatar),
  };
};

/**
 * *******
 * JSON Liking Data Schemas
 * *******
 */

const getOrganizationJsonld = (): OrganizationSchema => {
  return {
    "@type": "Organization",
    name: "Por Causa del Evangelio",
    url: process.env.GATSBY_SITE_URL,
    logo: {
      "@type": "ImageObject",
      url: `${process.env.GATSBY_SITE_URL}/icon.png`,
    },
  };
};

const getPersonJsonld = (author: IAuthor): PersonSchema => {
  return {
    "@type": "Person",
    "@id": getAuthorDetailRoute(author.slug),
    url: getAuthorDetailRoute(author.slug),
    name: author.fullname,
    identifier: author.id,
    description: toExcerptSEO(author.bio, 200),
    image: getImagesSEO(author.avatar).map(({ url }) => url),
    sameAs: author.socialNetworks?.map(({ url }) => url),
    jobTitle: author.role,
    worksFor: getOrganizationJsonld(),
  };
};
const getPotentialActionld = () => ({
  "@type": "SearchAction",
  "@id": getPageRoute("SEARCH"),
  target: {
    "@type": "EntryPoint",
    urlTemplate: `${getPageRoute("SEARCH")}?q={search_term_string}`,
  },
  "query-input": "required name=search_term_string",
});

export const getArticleJsonld = (
  article: NonNullable<IArticle>,
): ArticleSchemaWC => {
  return {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": getArticleDetailRoute(article.slug),
    },
    url: getArticleDetailRoute(article.slug),
    name: article.title,
    headline: article.title,
    image: getImagesSEO(article.banner).map(({ url }) => url),
    datePublished: article.publishedAt,
    dateModified: article.updatedAt,
    author: article.authors.map(getPersonJsonld),
    publisher: getOrganizationJsonld(),
    description: toExcerptSEO(article.body, 200),
    articleSection: article.category.name,
    keywords: article.tags.map(({ name }) => name.toLocaleLowerCase()),
  };
};

export const getWebsiteJsonld = ({
  name,
  headline,
  description,
  url,
}: {
  name: string;
  headline: string;
  description: string;
  url: string;
}): WebSiteSchemaWC => {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url,
    name,
    headline,
    description,
    dateCreated: "2019-01-01",
    keywords: [
      "causa",
      "evangelio",
      "ministerio",
      "artículos",
      "biblia",
      "iglesia",
      "adventista",
    ],
    sameAs: [
      "https://www.facebook.com/porcausadelevangelio",
      "https://www.instagram.com/porcausadelevangelio",
      "https://www.twitter.com/pcevangelio",
    ],
    potentialAction: getPotentialActionld(),
  };
};

export const getHomePageJsonld = ({
  name,
  articles,
}: {
  name: string;
  articles: IArticle[];
}): WebPageSchemaWC => {
  const itemListElement: ListItemSchema[] =
    articles?.map((article, index) => ({
      "@type": "ListItem",
      position: index + 1,
      item: getArticleJsonld(article),
    })) || [];

  return {
    "@context": "https://schema.org",
    "@type": "WebPage",
    url: process.env.GATSBY_SITE_URL,
    name,
    publisher: getOrganizationJsonld(),
    mainEntity: {
      "@type": "ItemList",
      itemListElement,
    },
  };
};

export const getAuthorJSONLD = (
  author: IAuthor,
): PersonSchemaWC | undefined => {
  if (!author.title) {
    return undefined;
  }

  const data = getPersonJsonld(author);

  // eslint-disable-next-line prefer-object-spread
  return Object.assign(
    {},
    {
      "@context": "https://schema.org",
    },
    data,
  ) as PersonSchemaWC;
};

export const getAboutUsJSONLD = ({
  description,
  founders,
  name,
}: {
  name: string;
  description: string;
  founders: IAuthor[];
}): AboutPageSchemaWC => {
  const organization = getOrganizationJsonld() as any;

  return {
    "@context": "https://schema.org",
    "@type": "AboutPage",
    name,
    dateCreated: "2019-01-01",
    mainEntity: {
      ...organization,
      description: toExcerptSEO(description, 200),
      founders: founders.map(getPersonJsonld),
    },
  };
};

export const getResourcesPageJsonld = ({
  name,
  description,
}: {
  name: string;
  description: string;
}): WebPageSchemaWC => {
  return {
    "@context": "https://schema.org",
    "@type": "WebPage",
    url: getPageRoute("RESOURCES"),
    name,
    description,
  };
};

export const getResourcesCarouselJsonld = ({
  resources,
  name,
}: {
  name: string;
  resources: IResource[];
}): ItemListSchemaWC => {
  return {
    "@context": "https://schema.org",
    "@type": "ItemList",
    name,
    itemListElement: resources.map((resource, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: resource.title,
      description: resource.description,
      url: resource.link,
      image: getImagesSEO(resource.banner).map(({ url }) => url),
    })),
  };
};
