import React from "react";

import { Mixins } from "@core";
import { Flex, Icon, Input, Button, IconButton } from "@atoms";
import { IStyledBoxProps } from "@interfaces";

type IIconProps = React.ComponentProps<typeof Icon>;
type IButtonProps = React.ComponentProps<typeof Button>;
type IInputProps = React.ComponentProps<typeof Input>;
interface ISearchBoxProps extends IStyledBoxProps {
  showSearchIcon?: boolean;
  showButton?: boolean;
  searchIconProps?: Omit<IIconProps, "name" | "volume">;
  buttonProps?: Omit<IButtonProps, "children">;
  inputProps?: Omit<IInputProps, "value" | "onChange">;
  value?: IInputProps["value"];
  onChange?: IInputProps["onChange"];
  onSubmit?: () => void;
  onClearPress?(): void;
  buttonTitle?: string;
  placeholder?: string;
}

const SearchBox: React.FC<ISearchBoxProps> = ({
  showSearchIcon,
  searchIconProps,
  buttonProps,
  showButton,
  buttonTitle,
  value,
  onChange,
  onSubmit,
  inputProps,
  onClearPress,
  placeholder,
  ...props
}) => (
  <Flex alignItems="center">
    <Flex
      alignItems="center"
      border={1}
      borderColor="borderColorLight"
      pl={3}
      width="100%"
      {...props}
    >
      {showSearchIcon ? (
        <Icon
          color="grayDark"
          {...searchIconProps}
          name="search"
          volume="small"
          mr={2}
        />
      ) : null}
      <Input
        type="text"
        name="search-box"
        bg="transparent"
        color="paleSky"
        placeholderColor="paleSky"
        fontSize={3}
        placeholder={placeholder!}
        height={Mixins.px2rem("42px")}
        width="100%"
        {...inputProps}
        value={value}
        onChange={onChange}
        onSubmit={onSubmit}
      />
      <IconButton
        color="grayDark"
        name="close"
        visibility={!value ? "hidden" : "initial"}
        onClick={onClearPress}
      />
    </Flex>
    {showButton ? (
      <Button
        color="secondary"
        shape="square"
        px={4}
        {...buttonProps}
        onClick={onSubmit}
      >
        {buttonTitle}
      </Button>
    ) : null}
  </Flex>
);

SearchBox.defaultProps = {
  showButton: true,
  showSearchIcon: true,
  value: "",
  placeholder: "Buscar...",
  buttonTitle: "",
  inputProps: {},
  searchIconProps: {},
  buttonProps: {},
  onChange: () => {},
  onClearPress: () => {},
  onSubmit: () => {},
};

export default SearchBox;
