type Queries<T> = {
  sm?: T;
  md?: T;
  lg?: T;
  xl?: T;
  xxl?: T;
  uhd?: T;
};

const queriesKeys = ["sm", "md", "lg", "xl", "xxl", "uhd"] as const;

export const obj2arr = <T>(queries: Queries<T>) => {
  return queriesKeys.map((q) => {
    return queries[q] ?? null;
  });
};
