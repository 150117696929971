export const hex2rgba = (hex: string, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r},${g},${b},${alpha})`;
};

export const hex2hlsObj = (
  hex: string,
): { h: number; l: number; s: number } => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = (max + min) / 2;
  let s = (max + min) / 2;
  let l = (max + min) / 2;

  if (max === min) {
    // eslint-disable-next-line no-multi-assign
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    // eslint-disable-next-line default-case
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  s *= 100;
  s = Math.round(s);
  l *= 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  return { h, s, l };
};

const stripUnit = (value: string): number => {
  const isValid = /^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/.test(value);
  return isValid ? parseFloat(value) : 0;
};

const pxFactory =
  (suffix: "em" | "rem") =>
  (value: string, base = "16px"): string => {
    return `${stripUnit(value) / stripUnit(base)}${suffix}`;
  };

export const px2rem = pxFactory("rem");

export const px2em = pxFactory("em");
