import { gql } from "@apollo/client";

export const CATEGORY_FIELDS = gql`
  fragment CategoryFields on Category {
    __typename
    createdAt
    name
  }
`;

export const TAGS_FIELDS = gql`
  fragment TagFields on Tag {
    __typename
    createdAt
    name
  }
`;

export const UPLOAD_FILE_FIELDS = gql`
  fragment UploadFileFields on UploadFile {
    name
    hash
    alternativeText
    caption
    url
    formats
    width
    height
  }
`;

export const AUTHOR_FIELDS = gql`
  ${UPLOAD_FILE_FIELDS}
  fragment AuthorFields on Team {
    __typename
    fullname
    slug
    title
    role
    bio
    createdAt
    socialNetworks {
      id
      __typename
      socialNetwork
      url
    }
    avatar {
      __typename
      data {
        id
        __typename
        attributes {
          ...UploadFileFields
        }
      }
    }
  }
`;

export const ARTICLE_FIELDS = gql`
  ${UPLOAD_FILE_FIELDS}
  ${AUTHOR_FIELDS}
  ${CATEGORY_FIELDS}
  ${TAGS_FIELDS}
  fragment ArticleFields on Article {
    __typename
    title
    slug
    publishedAt
    updatedAt
    readingTime
    body
    banner {
      __typename
      data {
        id
        __typename
        attributes {
          ...UploadFileFields
        }
      }
    }
    category {
      __typename
      data {
        id
        __typename
        attributes {
          ...CategoryFields
        }
      }
    }
    tags {
      __typename
      data {
        id
        __typename
        attributes {
          ...TagFields
        }
      }
    }
    authors {
      __typename
      data {
        id
        __typename
        attributes {
          ...AuthorFields
        }
      }
    }
  }
`;
