import React from "react";
import { GatsbySeo, GatsbySeoProps } from "gatsby-plugin-next-seo";
import { useStaticQuery, graphql } from "gatsby";

import { ThingSchema, ThingSchemaWC, WebSiteSchemaWC } from "@interfaces";

import { BrowserUtil, SEOUtil } from "@utils";
import { Routes } from "@core";
import JSONLD from "../jsonld";

interface Props extends Pick<GatsbySeoProps, "openGraph"> {
  json?: ThingSchemaWC | ThingSchemaWC[];
}

const SEO = ({ json, openGraph, ...props }: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `,
  );

  const seoTitle: string = React.useMemo(() => {
    if (!openGraph?.title) {
      return site.siteMetadata.title;
    }

    if (openGraph?.title.indexOf("|") !== -1) {
      return openGraph?.title;
    }

    return `${openGraph?.title} | ${site.siteMetadata?.title}`;
  }, [openGraph]);

  const seoDescription: string = React.useMemo(
    () => openGraph?.description || site.siteMetadata.description,
    [openGraph?.description],
  );

  const canonical = React.useMemo(() => {
    if (typeof window !== "undefined") {
      return window.location.origin + window.location.pathname;
    }
    return "";
  }, []);

  const renderJSONLinkingData = React.useMemo(() => {
    if (!json && !Array.isArray(json)) {
      return null;
    }

    const list = Array.isArray(json) ? json : [json];

    return (
      <>
        {list.map((jsonld, index) => (
          <JSONLD<ThingSchema> key={`json-schema-${index * 1}`} json={jsonld} />
        ))}
      </>
    );
  }, [json]);

  const twImage = React.useMemo(() => {
    if (openGraph?.images?.length) {
      return [
        {
          property: "twitter:image",
          content: openGraph.images[0].url,
        },
        {
          property: "twitter:image:alt",
          content: openGraph.images[0].url ?? "",
        },
      ];
    }

    return [];
  }, [openGraph]);

  return (
    <>
      <GatsbySeo
        title={seoTitle}
        description={seoDescription}
        canonical={canonical}
        language="es"
        openGraph={{
          ...(openGraph || {}),
          title: seoTitle,
        }}
        twitter={{
          cardType: "summary_large_image",
          handle: "@pcevangelio",
          site: "@pcevangelio",
        }}
        metaTags={[
          {
            property: "twitter:title",
            content: seoTitle,
          },
          {
            property: "twitter:description",
            content: seoDescription,
          },
          ...twImage,
        ]}
        {...props}
      />
      {BrowserUtil.isBrowser() && window.location.pathname === Routes.HOME ? (
        <JSONLD<WebSiteSchemaWC>
          json={SEOUtil.getWebsiteJsonld({
            url: site.siteMetadata.siteUrl,
            name: site.siteMetadata.title,
            headline: `Portal Cristiano | ${site.siteMetadata.title}`,
            description: site.siteMetadata.description,
          })}
        />
      ) : null}
      {renderJSONLinkingData}
    </>
  );
};

SEO.defaultProps = {
  json: undefined,
};

export default SEO;
