// based on https://github.com/developit/dlv

import { get as getCss } from "@styled-system/css";
import { DefaultTheme } from "styled-components";

/**
 * Get style values from the theme using responsive value.
 * @param scaleName Scale Name
 * @param styles Style | List of Styles
 * @returns
 */
export const get = (
  scaleName: keyof DefaultTheme,
  value: string | number | (string | number)[],
) => {
  return (theme: DefaultTheme) => {
    if (!value) {
      return {};
    }
    const values = Array.isArray(value) ? value : [value];
    const results = values.map((style) => {
      return getCss(theme, `${scaleName}.${style}`);
    });

    return results?.length === 1 ? results[0] : results;
  };
};
