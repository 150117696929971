import React from "react";
import { Button } from "@atoms";
import { IMouseEvents, IStyledTextProps } from "@interfaces";

interface IProps extends IMouseEvents, IStyledTextProps {
  title: string;
  selected: boolean;
}

const RadioButton: React.FC<IProps> = ({ title, selected, ...rest }) => {
  const styleProps: IStyledTextProps = React.useMemo(() => {
    return {
      fontFamily: selected ? "medium" : "medium",
      type: selected ? "solid" : "outline",
    };
  }, [selected]);

  return (
    <Button
      {...rest}
      {...styleProps}
      shape="rounded"
      color="primary"
      hoverColor="white"
    >
      {title}
    </Button>
  );
};

export default RadioButton;
