exports.components = {
  "component---src-pages-404-ts": () => import("./../../../src/pages/404.ts" /* webpackChunkName: "component---src-pages-404-ts" */),
  "component---src-pages-articulo-[slug]-tsx": () => import("./../../../src/pages/articulo/[slug].tsx" /* webpackChunkName: "component---src-pages-articulo-[slug]-tsx" */),
  "component---src-pages-articulos-ts": () => import("./../../../src/pages/articulos/[...].ts" /* webpackChunkName: "component---src-pages-articulos-ts" */),
  "component---src-pages-equipo-[slug]-tsx": () => import("./../../../src/pages/equipo/[slug].tsx" /* webpackChunkName: "component---src-pages-equipo-[slug]-tsx" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-recursos-ts": () => import("./../../../src/pages/recursos.ts" /* webpackChunkName: "component---src-pages-recursos-ts" */),
  "component---src-pages-sobre-nosotros-tsx": () => import("./../../../src/pages/sobre-nosotros.tsx" /* webpackChunkName: "component---src-pages-sobre-nosotros-tsx" */)
}

