import { DefaultTheme } from "styled-components";

import palette from "./palette";
import breakpoints from "./breakpoints";

const theme: DefaultTheme = {
  colors: {
    // basics
    transparent: palette.transparent,
    black: palette.black,
    white: palette.white,
    orange: palette.orange,
    orangeWhite: palette.orangeWhite,
    paleSky: palette.paleSky,
    error: palette.error,
    inherit: "inherit", // Color inherence

    // theme colors
    primary: palette.godGray,
    primaryDark: palette.ebonyClay,
    secondary: palette.azure,
    accent: palette.lochmara,
    background: palette.white,
    backgroundSecondary: palette.flashWhite,
    foreground: palette.zumthor,
    grayDark: palette.grayCadet,
    textColor: palette.godGray,
    borderColor: palette.paleSky,
    borderColorLight: palette.platimun,
    borderColorSecondary: palette.gray,

    // social networks
    facebook: palette.facebook,
    twitter: palette.twitter,
    youtube: palette.youtube,
    linkedin: palette.linkedin,
    instagram: palette.instagram,
    whatsapp: palette.whatsapp,
  },
  fonts: {
    light: "SourceSansPro-Light",
    regular: "SourceSansPro-Regular",
    bold: "SourceSansPro-Bold",
    medium: "SourceSansPro-Semibold",
  },
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  fontSizes: [
    "0.5em",
    "0.625em",
    "0.875em",
    "1em",
    "1.125em",
    "1.25em",
    "1.5em",
    "2.25em",
    "3em",
    "3.75em",
    "6em",
    "8em",
  ],
  space: ["0em", "0.25em", "0.5em", "1em", "2em", "4em", "8em"],
  breakpoints,
  lineHeights: {
    solid: 1.3,
    title: 1.25,
    copy: 1.7,
    body: 2,
    heading: 1.2,
  },
  letterSpacings: {
    copy: "0",
    normal: "normal",
    heading: "0.25em",
  },
  borders: [
    "0px solid",
    "1px solid",
    "2px solid",
    "4px solid",
    "8px solid",
    "16px solid",
    "32px solid",
  ],
  radii: [0, 2, 4, 16, "50%", "100%"],
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  shadows: {},
};

export default theme;
