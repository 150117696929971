import { BrowserUtil } from "@utils";
import React from "react";

const emptyLocationObject = {
  hash: "",
  host: "",
  hostname: "",
  href: "",
  origin: "",
  pathname: "",
  port: "",
  protocol: "",
  search: "",
};

const useLocation = () => {
  const [location, setLocation] = React.useState(
    BrowserUtil.isBrowser() ? window.location : emptyLocationObject,
  );

  React.useEffect(() => {
    const handleLocationChange = () => {
      setLocation(window.location);
    };

    window.addEventListener("popstate", handleLocationChange);
    window.addEventListener("hashchange", handleLocationChange);

    return () => {
      window.removeEventListener("popstate", handleLocationChange);
      window.removeEventListener("hashchange", handleLocationChange);
    };
  }, []);

  return location;
};

export default useLocation;
