import React from "react";
import { navigate } from "gatsby";

import { Flex, IconButton, Box, Image, Container, LinkRouter } from "@atoms";
import { Constants, Mixins, Routes } from "@core";
import { BrowserUtil, EventUtil, ResponsiveUtil } from "@utils";
import { QueryObjectType } from "@interfaces";
import { MenuLink, SearchBox } from "@molecules";

const NAVBAR_CONTAINER_PADDING = ResponsiveUtil.obj2arr<QueryObjectType>({
  ...Constants.CONTAINER_PADDING_X,
  sm: Mixins.px2rem("4px"),
});

const NavbarMenuLink: React.FC<{ title: string; href: string }> = ({
  title,
  href,
}) => {
  return (
    <MenuLink
      title={title}
      fontFamily="regular"
      color="inherit"
      to={href}
      fontSize={[5, null, 3]}
      buttonProps={{
        mt: [3, null, 0],
        mr: ResponsiveUtil.obj2arr({ lg: 3, xl: 4 }),
        color: ["accent", "accent", "white"],
      }}
    />
  );
};

const links = [
  {
    title: "Nosotros",
    href: `${Routes.ABOUT}`,
  },
  {
    title: "Artículos",
    href: `${Routes.SEARCH}`,
  },
  {
    title: "Recursos",
    href: `${Routes.RESOURCES}`,
  },
];

const SearchBoxNavbar: React.FC<{
  searchText: string;
  onSearchTextChange: (s: string) => void;
  onClearSearchTextPressed: () => void;
  isSidebar: boolean;
  onSubmit: () => void;
}> = ({
  searchText,
  onSearchTextChange,
  onClearSearchTextPressed,
  isSidebar = false,
  onSubmit,
}) => {
  return (
    <SearchBox
      display={isSidebar ? ["flex", "none"] : ["none", "flex"]}
      showButton={false}
      backgroundColor={["white", "transparent"]}
      color={["white", "transparent"]}
      value={searchText}
      onChange={EventUtil.callOnChange(onSearchTextChange)}
      onClearPress={onClearSearchTextPressed}
      onSubmit={onSubmit}
      mr={[null, 3, 0]}
      inputProps={{
        color: ["primary", "white"],
        placeholderColor: ["primary", "white"],
      }}
    />
  );
};

const Navbar = () => {
  const [isOpen, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  const onMenuClick = React.useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const onSearchTextChange = React.useCallback((s: string) => {
    setSearchText(s);
  }, []);

  const onClearSearchTextPressed = React.useCallback(() => {
    setSearchText("");
  }, []);

  const onSearchSubmit = React.useCallback(() => {
    if (searchText.length > 0) {
      navigate(`${Routes.SEARCH}?q=${searchText}`);
    }
  }, [searchText]);

  const isSearchBoxVisible = React.useMemo(() => {
    if (!BrowserUtil.isBrowser()) {
      return false;
    }
    const actualPathname = window.location.pathname;

    if (actualPathname.includes(Routes.SEARCH)) {
      return false;
    }

    return true;
  }, []);

  React.useEffect(() => {
    BrowserUtil.toggleClass({
      selector: "body",
      className: "mobile-navbar-open",
      force: isOpen,
    });
  }, [isOpen]);

  return (
    <Box bg="primary">
      <Container
        variant="layout"
        height={Constants.NAVBAR_HEIGHT}
        paddingX={NAVBAR_CONTAINER_PADDING}
        paddingLeft={[Constants.CONTAINER_PADDING_X.sm, null]}
      >
        <Flex
          flexDirection="row"
          justifyContent={["space-between"]}
          alignItems="center"
          height="100%"
        >
          <Box height={[Mixins.px2rem("42px")]}>
            <LinkRouter to={Routes.HOME}>
              <Image src="/logo.svg" height="100%" alt="logo" />
            </LinkRouter>
          </Box>
          <Flex display={["none", null, "flex"]}>
            {React.Children.toArray(links.map(NavbarMenuLink))}
          </Flex>
          <Flex>
            <Box visibility={isSearchBoxVisible ? "visible" : "hidden"}>
              <SearchBoxNavbar
                searchText={searchText}
                onClearSearchTextPressed={onClearSearchTextPressed}
                onSearchTextChange={onSearchTextChange}
                isSidebar={false}
                onSubmit={onSearchSubmit}
              />
            </Box>
            <IconButton
              display={["block", null, "none"]}
              name="menu"
              color="white"
              onClick={onMenuClick}
            />
          </Flex>
        </Flex>
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          zIndex={999}
          display={isOpen ? "block" : "none"}
          height="150vh"
          width="100vw"
          bg="foreground"
        >
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="flex-end"
            height={Constants.NAVBAR_HEIGHT}
            paddingX={ResponsiveUtil.obj2arr<QueryObjectType>({
              ...Constants.CONTAINER_PADDING_X,
              sm: 0,
            })}
            bg="primary"
          >
            <IconButton
              name="close"
              color="white"
              height={Mixins.px2rem("30px")}
              width="auto"
              onClick={onMenuClick}
            />
          </Flex>
          <Box py={4} px={3}>
            {isSearchBoxVisible && (
              <SearchBoxNavbar
                searchText={searchText}
                onClearSearchTextPressed={onClearSearchTextPressed}
                onSearchTextChange={onSearchTextChange}
                isSidebar
                onSubmit={onSearchSubmit}
              />
            )}
            <Box mt={[4, 0]}>
              {React.Children.toArray(links.map(NavbarMenuLink))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Navbar;
