import React from "react";

import { Button, LinkRouter } from "@atoms";

type ILinkProps = React.ComponentProps<typeof LinkRouter>;

interface IProps extends ILinkProps {
  title: string;
  buttonProps?: React.ComponentProps<typeof Button>;
}

const MenuLink: React.FC<IProps> = ({ title, buttonProps, ...props }) => {
  return (
    <LinkRouter {...props}>
      <Button type="ghost" width="100%" {...buttonProps}>
        {title}
      </Button>
    </LinkRouter>
  );
};

MenuLink.defaultProps = {
  buttonProps: {},
};

export default MenuLink;
