import React from "react";

import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import { Client } from "@graphql-client";

const ApolloClientContext: React.FC<{}> = ({ children }) => {
  const [apolloClient] = React.useState<ApolloClient<NormalizedCacheObject>>(
    Client.getClient(),
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloClientContext;
