import { IMouseEvents, IStyledBoxProps } from "@interfaces";
import React from "react";
import Button from "../button";
import Icon from "../icon";

type IIconProps = Pick<
  React.ComponentProps<typeof Icon>,
  "name" | "size" | "volume"
>;

interface IProps extends IStyledBoxProps, IMouseEvents {
  name: IIconProps["name"];
  size?: IIconProps["size"];
  volume?: IIconProps["volume"];
}

const IconButton: React.FC<IProps> = ({ name, size, volume, ...props }) => (
  <Button
    {...props}
    iconName={name}
    type="ghost"
    iconProps={{
      size,
      volume,
    }}
  />
);

IconButton.defaultProps = {
  size: "",
  volume: "medium",
};

export default IconButton;
