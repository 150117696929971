import React, { HTMLAttributeAnchorTarget } from "react";

import { IStyledBoxProps } from "@interfaces";

import Link from "../link";
import IconButton from "../iconButton";

type IconButtonProps = React.ComponentProps<typeof IconButton>;

interface IProps extends IStyledBoxProps {
  href: string;
  name: IconButtonProps["name"] | string;
  target: HTMLAttributeAnchorTarget;
  color?: IconButtonProps["color"];
  volume?: IconButtonProps["volume"];
}

const SocialButton: React.FC<IProps> = ({ name, color, volume, ...props }) => {
  const colorProp = React.useMemo(() => {
    return color === "inherit" ? name : color;
  }, []);

  return (
    <Link {...props}>
      <IconButton
        name={name as IconButtonProps["name"]}
        color={colorProp as IconButtonProps["color"]}
        volume={volume}
        px={1}
      />
    </Link>
  );
};

SocialButton.defaultProps = {
  color: "white",
  volume: "medium",
};

export default SocialButton;
