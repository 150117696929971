// eslint-disable-next-line @typescript-eslint/no-explicit-any
const breakpoints = ["48em", "62em", "75em", "87.5em", "160em"] as any;

/* eslint-disable prefer-destructuring */
// aliases
breakpoints.sm = 0;
breakpoints.md = breakpoints[0];
breakpoints.lg = breakpoints[1];
breakpoints.xl = breakpoints[2];
breakpoints.xxl = breakpoints[3];
breakpoints.uhd = breakpoints[4];
/* eslint-enable prefer-destructuring */

export default breakpoints;
