import React from "react";

import { StyledUtil } from "@utils";
import { IStyledBoxProps } from "@interfaces";

import Box from "../box";

const ImageBase = StyledUtil.styledAnyComponent(Box, {
  maxWidth: "100%",
});

type IProps = React.ComponentProps<typeof ImageBase> &
  IStyledBoxProps & {
    alt: string;
    src: string;
  };

const Image: React.FC<IProps> = ({ ...props }) => {
  return <ImageBase {...props} as="img" loading="lazy" />;
};

export default Image;
