export default {
  white: "#ffffff",
  flashWhite: "#f3f4f6",
  error: "#ff6961",
  black: "#000000",
  ebonyClay: "#1F2937",
  transparent: "rgba(0,0,0,0)",
  godGray: "#1a1a1a",
  azure: "#009fff",
  lochmara: "#0B87D1",
  zumthor: "#EFF6FF",
  orange: "#F59E0B",
  orangeWhite: "#f59e0bba",
  paleSky: "#6b7280",
  gray: "#707070",
  grayCadet: "#9ca3af",
  platimun: "#e2e2e2",
  facebook: "#3b5998",
  twitter: "#1da1f2",
  youtube: "#ff0000",
  linkedin: "#0a66c2",
  instagram: "#c32aa3",
  whatsapp: "#075e54",
};
