import { IAuthorGQL } from "@interfaces";
import Media from "./media";

const forceParseRoleValue = (role: String) => {
  if (!role) {
    return "";
  }

  return role
    .replaceAll("_", " ")
    .replace("Disenador", "Diseñador")
    .replace("Grafico", "Gráfico")
    .replace("Linea", "Línea");
};

const Author = ({ id, attributes }: IAuthorGQL) => {
  return {
    id,
    fullname: attributes.fullname,
    slug: attributes.slug,
    title: attributes.title,
    role: forceParseRoleValue(attributes.role ?? ""),
    bio: attributes.bio,
    url: attributes.url,
    createdAt: attributes.createdAt,
    avatar: Media(attributes.avatar.data),
    socialNetworks: attributes.socialNetworks,
  };
};

export type IAuthor = ReturnType<typeof Author>;

export default Author;
