import React from "react";

import { variant as variantFn } from "styled-system";
import { ResponsiveStyleValue } from "@styled-system/css";

import { Image } from "@atoms";
import { Mixins } from "@core";
import { StyledUtil } from "@utils";
import { IStyledBoxProps } from "@interfaces";

const Avatar = StyledUtil.styledAnyComponent(
  Image,
  {
    borderRadius: 4,
  },
  variantFn({
    prop: "size",
    variants: {
      xsmall: {
        width: Mixins.px2rem("24px"),
        height: Mixins.px2rem("24px"),
      },
      small: {
        width: Mixins.px2rem("80px"),
        height: Mixins.px2rem("80px"),
      },
      medium: {
        width: Mixins.px2rem("120px"),
        height: Mixins.px2rem("120px"),
      },
      large: {
        width: Mixins.px2rem("160px"),
        height: Mixins.px2rem("160px"),
      },
    },
  }),
);

type IProps = React.ComponentProps<typeof Image> &
  IStyledBoxProps & {
    size?: ResponsiveStyleValue<"xsmall" | "small" | "medium" | "large">;
  };

Avatar.defaultProps = {
  size: "medium",
};

export default Avatar as React.FC<IProps>;
