import React from "react";
import { StyledUtil } from "@utils";
import ReactMarkdown from "react-markdown";

import { IStyledTextProps } from "@interfaces";
import css from "@styled-system/css";
import styledComponents from "styled-components";

import Theme from "@theme";

const ReactMarkdownStyled = StyledUtil.styledAnyComponent("p", {
  margin: 0,
  fontFamily: "regular",
  fontWeight: 3,
  lineHeight: "body",
  color: "textColor",
  letterSpacing: "copy",
});

const Blockquote = StyledUtil.styledAnyComponent("blockquote", {
  fontFamily: "regular",
  lineHeight: "body",
  backgroundColor: "backgroundSecondary",
  borderLeft: "3px solid",
  borderLeftColor: "accent",
  color: "textColor",
  padding: "1rem",
  margin: "1.25rem 0",
});

const H4 = StyledUtil.styledAnyComponent("h4", {
  fontFamily: "bold",
  lineHeight: "title",
  fontSize: 5,
});

const H5 = StyledUtil.styledAnyComponent("h5", {
  fontFamily: "bold",
  lineHeight: "title",
  fontSize: 4,
});

const Anchor = styledComponents.a.attrs({ target: "_blank", color: "red" })`
  color: ${Theme.colors.accent};
`;

interface IProps
  extends IStyledTextProps,
    React.ComponentProps<typeof ReactMarkdown> {}

const Paragraph: React.FC<IProps> = (props) => {
  return (
    <ReactMarkdownStyled
      as={ReactMarkdown}
      components={{
        h1: "h2",
        a: Anchor,
        blockquote: Blockquote,
        h4: H4,
        h5: H5,
      }}
      {...props}
      css={css({
        "p:not(:first-child)": {
          mt: 3,
        },
        "blockquote > p": {
          fontStyle: "italic",
        },
      })}
    />
  );
};

export default Paragraph;
