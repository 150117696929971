import { Flex } from "@atoms";
import { IStyledBoxProps } from "@interfaces";
import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  XIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const hashtags = ["porcausadelevangelio"];

interface IProps extends IStyledBoxProps {
  url: string;
  title?: string;
  hashtag?: string;
  iconSize?: string | number;
  direction?: "horizontal" | "vertical";
}

const FloatSocialButtons = ({
  url,
  title,
  direction,
  iconSize,
  ...props
}: IProps) => {
  const shareButtonProps = {
    url,
    title,
    style: {
      marginBottom: direction === "vertical" ? "0.5rem" : 0,
      marginRight: direction === "horizontal" ? "0.5rem" : 0,
    },
  };

  const shareButtonIconProps = {
    size: iconSize,
    round: true,
  };

  return (
    <Flex
      {...props}
      flexDirection={direction === "vertical" ? "column" : "row"}
    >
      <WhatsappShareButton {...shareButtonProps} separator=" | ">
        <WhatsappIcon {...shareButtonIconProps} />
      </WhatsappShareButton>
      <TwitterShareButton {...shareButtonProps} hashtags={hashtags}>
        <XIcon {...shareButtonIconProps} />
      </TwitterShareButton>
      <FacebookShareButton {...shareButtonProps} hashtag={hashtags[0]}>
        <FacebookIcon {...shareButtonIconProps} />
      </FacebookShareButton>
      <TelegramShareButton {...shareButtonProps}>
        <TelegramIcon {...shareButtonIconProps} />
      </TelegramShareButton>
    </Flex>
  );
};

FloatSocialButtons.defaultProps = {
  title: "",
  hashtag: "",
  direction: "horizontal",
  iconSize: 30,
};

export default FloatSocialButtons;
