import React, { HTMLAttributeAnchorTarget } from "react";

import { StyledUtil } from "@utils";
import { IStyledTextProps } from "@interfaces";

import Box from "../box";

export interface ILinkProps extends React.ComponentPropsWithRef<"a"> {
  target: HTMLAttributeAnchorTarget;
}

const Link = StyledUtil.styledAnyComponent(Box, {
  textDecoration: "none",
  cursor: "pointer",
  color: "textColor",
}).withComponent("a");

export default Link as React.FC<ILinkProps & IStyledTextProps>;
