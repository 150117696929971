import { StyledUtil } from "@utils";
import React from "react";
import ReactLoadingSkeleton, { SkeletonProps } from "react-loading-skeleton";
import { FlexboxProps, MarginProps, PaddingProps } from "styled-system";

interface IProps
  extends SkeletonProps,
    MarginProps,
    FlexboxProps,
    PaddingProps {}

const ReactLoadingSkeletonBase = StyledUtil.styledAnyComponent(
  ReactLoadingSkeleton,
  {},
);

const Skeleton = ({ ...props }: IProps) => {
  return <ReactLoadingSkeletonBase {...props} />;
};

export default Skeleton;
