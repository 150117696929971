import { Box, Image } from "@atoms";
import { IStyledBoxProps, IUploadFileGQL, ImageType } from "@interfaces";
import { MediaUtil } from "@utils";
import React from "react";

type formats = keyof IUploadFileGQL["attributes"]["formats"];

interface IProps extends IStyledBoxProps {
  alt: string;
  image: ImageType;
  objectFit?: string;
  loadFormat?: formats | "original";
  preLoadFormat?: formats;
}

const ImageLazy = ({
  image,
  preLoadFormat = "thumbnail",
  loadFormat = "original",
  objectFit = "initial",
  width,
  height,
  ...props
}: IProps) => {
  const thumbSrc = React.useMemo(() => {
    if (!preLoadFormat || !image?.formats?.[preLoadFormat]) {
      return image?.formats?.thumbnail?.url ?? "";
    }

    return image?.formats?.[preLoadFormat]?.url ?? "";
  }, [preLoadFormat]);

  const [isLoaded, setIsLoaded] = React.useState(!thumbSrc);
  const [isThumbLoaded, setThumbLoaded] = React.useState(false);

  const onLoad = React.useCallback(() => {
    setIsLoaded(true);
  }, []);

  const onThumbLoad = React.useCallback(() => {
    setThumbLoaded(true);
  }, []);

  return (
    <Box width={width} height={height} position="relative">
      {thumbSrc ? (
        <Image
          src={thumbSrc}
          width={width}
          height={height}
          objectFit={objectFit}
          onLoad={onThumbLoad}
          {...props}
        />
      ) : null}
      {isThumbLoaded ? (
        <Image
          {...props}
          width={width}
          height={height}
          src={MediaUtil.getFormatUrl(
            image,
            loadFormat === "original" ? "main" : loadFormat,
          )}
          onLoad={onLoad}
          style={{
            position: "absolute",
            opacity: isLoaded ? 1 : 0,
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          objectFit={objectFit}
        />
      ) : null}
    </Box>
  );
};

ImageLazy.defaultProps = {
  objectFit: "initial",
  loadFormat: "original",
  preLoadFormat: "thumbnail",
};

export default ImageLazy;
