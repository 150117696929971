import * as SS from "styled-system";

import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import {
  IoSearchOutline,
  IoStarOutline,
  IoFilterSharp,
  IoChevronDown,
  IoChevronForward,
  IoHomeSharp,
  IoShareOutline,
  IoClose,
} from "react-icons/io5";
import { FiMenu } from "react-icons/fi";
import { IconType } from "react-icons";

import { variant } from "styled-system";

import { StyledUtil } from "@utils";
import { ColorProps } from "@interfaces";
import { Mixins } from "@core";

const SizeProp = SS.system({
  size: {
    properties: ["width", "height"],
    scale: "sizes",
  },
});

const getIconStyledSystem = (component: IconType) => {
  return StyledUtil.styledAnyComponent(
    component,
    { color: "primary" },
    SizeProp,
    variant({
      prop: "volume",
      variants: {
        small: {
          size: Mixins.px2rem("22px"),
        },
        medium: {
          size: Mixins.px2rem("32px"),
        },
      },
    }),
  );
};

const Icons = {
  facebook: getIconStyledSystem(FaFacebook),
  twitter: getIconStyledSystem(FaTwitter),
  instagram: getIconStyledSystem(FaInstagram),
  linkedIn: getIconStyledSystem(FaLinkedinIn),
  search: getIconStyledSystem(IoSearchOutline),
  youtube: getIconStyledSystem(FaYoutube),
  star: getIconStyledSystem(IoStarOutline),
  filter: getIconStyledSystem(IoFilterSharp),
  arrowDown: getIconStyledSystem(IoChevronDown),
  arrowForward: getIconStyledSystem(IoChevronForward),
  home: getIconStyledSystem(IoHomeSharp),
  share: getIconStyledSystem(IoShareOutline),
  close: getIconStyledSystem(IoClose),
  menu: getIconStyledSystem(FiMenu),
};

export type IIconProps = SS.SpaceProps &
  ColorProps &
  SS.LayoutProps &
  SS.FlexBasisProps &
  SS.BackgroundColorProps & {
    name: keyof typeof Icons;
    size?: SS.ResponsiveValue<number | string>;
    volume?: SS.ResponsiveValue<"small" | "medium">;
  };

export default Icons;
