import { IUploadFileGQL } from "@interfaces";

const Media = (props: IUploadFileGQL) => {
  const rawFormats = props?.attributes?.formats ?? {};
  const keys = Object.keys(rawFormats) as (keyof typeof rawFormats)[];
  const formats = keys.reduce<IUploadFileGQL["attributes"]["formats"]>(
    (acc, key) => {
      return {
        ...acc,
        [key]: {
          name: rawFormats[key]?.name,
          hash: rawFormats[key]?.hash,
          width: rawFormats[key]?.width,
          height: rawFormats[key]?.height,
          url: rawFormats[key]?.url.includes("http")
            ? rawFormats[key]?.url
            : `${process.env.GATSBY_API_SERVER}${rawFormats[key]?.url}`,
        },
      };
    },
    {} as typeof rawFormats,
  );

  return {
    id: props?.id,
    name: props?.attributes.name,
    hash: props?.attributes.hash,
    alternativeText: props?.attributes.alternativeText,
    caption: props?.attributes.caption,
    width: props?.attributes.width,
    height: props?.attributes.height,
    url: props?.attributes.url.includes("http")
      ? props?.attributes.url
      : `${process.env.GATSBY_API_SERVER}${props?.attributes.url}`,
    formats,
  };
};

export default Media;
