import React from "react";
import * as yup from "yup";

import { useMutation } from "@apollo/client";

import { EventUtil } from "@utils";
import { Mutations } from "@graphql-client";
import { Flex, Input, Button, Box, Text } from "@atoms";

const SubscribeSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const SubscribeBox = () => {
  const [loading, setLoading] = React.useState(false);

  const [subcribeEmail] = useMutation(Mutations.CREATE_SUBSCRIBER, {
    onCompleted: () => setLoading(false),
    errorPolicy: "all",
  });

  const [email, setEmail] = React.useState("");
  const [confirmationMessage, setConfirmationMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const resetAll = React.useCallback(() => {
    setConfirmationMessage("");
    setErrorMessage("");
    setEmail("");
  }, []);

  const handleSuscribe = React.useCallback(async () => {
    if (!email) {
      setErrorMessage("Por favor ingrese su correo eléctronico.");
      return;
    }

    const valid = SubscribeSchema.isValidSync({ email });

    if (!valid) {
      setErrorMessage("Correo eléctronico no es valido.");
      return;
    }

    setLoading(true);

    const { data, errors } = await subcribeEmail({
      variables: {
        email,
      },
    });

    if (errors) setErrorMessage("Ha ocurrido un error, intente nuevamente.");

    if (data?.createSubscriber) {
      resetAll();
      setConfirmationMessage("Correo suscrito exitosamente.");
      setTimeout(() => resetAll(), 5000);
    }
  }, [email]);

  const onSuscribeChangeText = React.useCallback((value: string) => {
    setErrorMessage("");
    setConfirmationMessage("");
    setEmail(value);
  }, []);

  return (
    <Box>
      <Flex flexDirection="row" display={["block", "flex"]}>
        <Input
          name="emailSubscribe"
          placeholder="Correo eléctronico"
          color={!errorMessage ? "primary" : "error"}
          fontFamily="medium"
          onChange={EventUtil.callOnChange(onSuscribeChangeText)}
          value={email}
          width="100%"
          px={3}
          borderBottomRightRadius={0}
          borderTopRightRadius={0}
          placeholderProps={{
            color: "borderColorSecondary",
            textAlign: ["center", "left"],
          }}
        />
        <Button
          width={["100%", "auto"]}
          color="secondary"
          shape="square"
          px={[4]}
          onClick={!loading ? handleSuscribe : undefined}
        >
          {loading ? "Enviando..." : "Suscribirme"}
        </Button>
      </Flex>
      <Box my={1}>
        {errorMessage && (
          <Text color="error" textAlign={["center", null, "right"]}>
            {errorMessage}
          </Text>
        )}
        {confirmationMessage && (
          <Text color="accent" textAlign={["center", null, "right"]}>
            {confirmationMessage}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default SubscribeBox;
