export const toggleClass = ({
  selector,
  className,
  force,
}: {
  selector: string;
  className: string;
  force?: boolean;
}): boolean => {
  const element = document.querySelector<HTMLElement>(selector);
  if (!element) {
    return false;
  }

  return element.classList.toggle(className, force);
};

export const isBrowser = () => typeof window !== "undefined";
