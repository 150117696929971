import { Routes } from "@core";
import { navigate } from "gatsby";

export const navigateTo = navigate;

export const getPageRoute = (page: keyof typeof Routes) => {
  const route = Routes[page];
  return `${process.env.GATSBY_SITE_URL}${route}/`;
};

export const getArticleDetailRoute = (slug: string) =>
  `${process.env.GATSBY_SITE_URL}${Routes.ARTICLE_DETAIL.replace(
    ":slug",
    slug,
  )}/`;

export const getAuthorDetailRoute = (slug: string) =>
  `${process.env.GATSBY_SITE_URL}${Routes.AUTHOR_DETAIL.replace(
    ":username",
    slug,
  )}/`;

const validParams = ["q", "t", "cat"];
export const updateSearchParams = (params: {
  q?: string;
  t?: string;
  cat?: string;
}) => {
  const searchParams = new URLSearchParams(window.location.search);

  Object.entries(params).forEach(([key, value]) => {
    if (value && validParams.indexOf(key) !== -1) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
  });

  const path = window.location.pathname;
  const newUrl = `${path}?${searchParams.toString()}`;
  window.history.replaceState(null, "", newUrl);

  navigate(newUrl, { replace: false });
};

// TODO Add navigation to search page
export const navigateToSearch = () => {};

// TODO Add navigation to 404 page
export const navigateTo404 = () => {};
