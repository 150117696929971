import { gql } from "@apollo/client";

export const CREATE_SUBSCRIBER = gql`
  mutation CREATE_SUBSCRIBER($email: String!) {
    createSubscriber(data: { email: $email }) {
      __typename
      data {
        __typename
        id
      }
    }
  }
`;
