import React from "react";
import { StyledUtil } from "@utils";
import { variant } from "styled-system";

import { ResponsiveStyleValue } from "@styled-system/css";

import { IStyledTextProps } from "@interfaces";

interface IProps extends IStyledTextProps {
  variant?: ResponsiveStyleValue<"regular" | "bold">;
}

const Heading = StyledUtil.styled(
  "h1",
  {
    textTransform: "uppercase",
    letterSpacing: 0,
  },
  variant({
    variants: {
      regular: {
        fontFamily: "regular",
        lineHeight: "title",
      },
      bold: {
        fontFamily: "bold",
        lineHeight: "title",
      },
    },
  }),
);

Heading.displayName = "Heading";

Heading.defaultProps = {
  variant: "bold",
};

export default Heading as React.FC<IProps>;
