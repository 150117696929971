import React from "react";

import { Routes } from "@core";
import { MenuLink } from "@molecules";

interface IProps extends Omit<React.ComponentProps<typeof MenuLink>, "to"> {
  title: string;
}

const Tag: React.FC<IProps> = ({ title, buttonProps, ...props }) => {
  return (
    <MenuLink
      {...props}
      title={`#${title}`}
      to={`${Routes.SEARCH}?t=${title}`}
      buttonProps={{
        volume: "tag",
        type: "ghost",
        shape: "square",
        hoverColor: "white",
        ...buttonProps,
      }}
    />
  );
};

export default Tag;
