import React from "react";

import { MenuLink } from "@molecules";
import { Constants, Mixins, Routes } from "@core";
import { Box, Container, Flex, Heading, SEO, Text } from "@atoms";
import { BrowserUtil } from "@utils";

import Layout from "../layout";

const NotFoundTemplate = () => {
  const height = BrowserUtil.isBrowser() ? window.innerHeight : 0;

  return (
    <Layout>
      <SEO
        openGraph={{
          title: "Página no encontrada",
        }}
      />
      <Container variant="compacted">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={`calc(${Mixins.px2rem(`${height}px`)} - ${
            Constants.NAVBAR_HEIGHT
          })`}
        >
          <Heading variant="regular" fontSize={11} opacity="0.8">
            404
          </Heading>
          <Text fontSize={[4, 5]} mb={2} textAlign="center" opacity="0.9">
            Lo que estás buscando no existe o no está disponible.
          </Text>
          <Box py={3}>
            <MenuLink
              to={Routes.HOME}
              title="Ir a la página principal"
              buttonProps={{
                color: "secondary",
                volume: "medium",
                type: "solid",
              }}
            />
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default NotFoundTemplate;
