import { ICategoryArticlesGQL, ICategoryGQL } from "@interfaces";
import Article from "./article";

const Category = ({ id, attributes }: ICategoryGQL) => {
  return {
    id,
    name: attributes?.name,
    createdAt: attributes?.createdAt,
  };
};

Category.Articles = (category: ICategoryArticlesGQL) => {
  return {
    ...Category(category),
    articles: category?.attributes?.articles?.data?.map(Article) ?? [],
  };
};
export type ICategory = ReturnType<typeof Category>;

export default Category;
