import React from "react";

import { IStyledTextProps } from "@interfaces";
import { EventUtil, NavigationUtil } from "@utils";
import Link from "../link";

type TLink = React.ComponentProps<typeof Link>;

export interface IProps extends Omit<Partial<TLink>, "href"> {
  to: string;
}

const LinkRouter = ({ to, ...props }: IProps) => {
  const onClick = React.useCallback(() => {
    NavigationUtil.navigateTo(to);
  }, [to]);

  return (
    <Link
      {...props}
      href={to}
      target="_self"
      onClick={EventUtil.onClickHandler(onClick)}
    />
  );
};

export default LinkRouter as React.FC<IStyledTextProps & IProps>;
