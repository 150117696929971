import React from "react";
import * as StyledSystemCss from "@styled-system/css";
import { variant as variantFn } from "styled-system";
import { useTheme } from "styled-components";

import { StyledUtil, CssUtil } from "@utils";
import { ColorProps, IMouseEvents, IStyledTextProps } from "@interfaces";
import { Mixins } from "@core";

import Flex from "../flex";
import Icon from "../icon";

const IconWrapper = StyledUtil.styledAnyComponent(Icon, {});

const ButtonBase = StyledUtil.styledAnyComponent(
  Flex,
  {
    justifyItems: "center",
    appearance: "none",
    outline: "none",
    cursor: "pointer",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    border: 0,
    px: Mixins.px2em("12px"),
    fontFamily: "bold",
  },
  variantFn({
    prop: "type",
    variants: {
      solid: {
        color: "white",
        backgroundColor: "var(--variant-color)",
        "&:hover": {
          filter: "opacity(0.9)",
        },
        [IconWrapper]: {
          color: "white",
        },
      },
      outline: {
        backgroundColor: "transparent",
        borderColor: "var(--variant-color)",
        color: "var(--variant-color)",
        border: 1,
        "&:hover": {
          filter: "opacity(0.9)",
          color: "var(--variant-color-outline)",
          backgroundColor: "var(--variant-color)",
          borderColor: "var(--variant-color)",
          [IconWrapper]: {
            color: "var(--variant-color-outline)",
          },
        },
        [IconWrapper]: {
          color: "var(--variant-color)",
        },
      },
      ghost: {
        border: 1,
        borderColor: "transparent",
        backgroundColor: "transparent",
        color: "var(--variant-color)",
        "&:hover": {
          borderColor: "var(--variant-ghost-border-color)",
        },
        [IconWrapper]: {
          color: "var(--variant-color)",
        },
      },
    },
  }),
  variantFn({
    prop: "shape",
    variants: {
      base: {
        borderRadius: 2,
      },
      square: {
        borderRadius: 0,
      },
      rounded: {
        borderRadius: "12px",
      },
    },
  }),
  variantFn({
    prop: "volume",
    variants: {
      tag: {
        height: "auto",
        fontSize: 2,
      },
      small: {
        height: Mixins.px2rem("35px"),
        fontSize: 2,
      },
      medium: {
        height: Mixins.px2rem("42px"),
        fontSize: 3,
      },
    },
  }),
);

type IIconProps = React.ComponentProps<typeof Icon>;

type IProps = React.ComponentProps<typeof ButtonBase> &
  IStyledTextProps &
  IMouseEvents & {
    type?: StyledSystemCss.ResponsiveStyleValue<"solid" | "outline" | "ghost">;
    shape?: StyledSystemCss.ResponsiveStyleValue<"base" | "square" | "rounded">;
    volume?: StyledSystemCss.ResponsiveStyleValue<"small" | "medium" | "tag">;
    iconName?: IIconProps["name"] | undefined;
    iconProps?: Omit<IIconProps, "name">;
    hoverColor?: ColorProps["color"];
  };

const Button: React.FC<IProps> = ({
  children,
  iconName,
  color,
  iconProps,
  type,
  hoverColor,
  ...props
}) => {
  const theme = useTheme();
  const css = React.useMemo(() => {
    const varVariantColor = CssUtil.get("colors", color as string)(theme);
    const mHoverColor = CssUtil.get("colors", hoverColor as string)(theme);

    return StyledSystemCss.css({
      "--variant-color": varVariantColor,
      "--variant-ghost-border-color":
        type === "ghost" && iconName
          ? "transparent"
          : mHoverColor || varVariantColor,
      "--variant-color-outline": mHoverColor,
    })({ theme });
  }, [color]);

  return (
    <ButtonBase {...props} type={type} css={css} as="button">
      {iconName ? (
        <IconWrapper
          volume="small"
          mr={!children ? 0 : 2}
          {...iconProps}
          name={iconName}
        />
      ) : null}
      {children}
    </ButtonBase>
  );
};

Button.defaultProps = {
  type: "solid",
  shape: "base",
  volume: "medium",
  iconName: undefined,
  iconProps: {},
  hoverColor: "primary",
};

export default Button;
