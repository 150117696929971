import React from "react";
import { variant as variantFn } from "styled-system";

import { StyledUtil } from "@utils";
import { IStyledTextProps, OnSelectChangeEventType } from "@interfaces";
import { Mixins, Constants } from "@core";

import Flex from "../flex";
import Box from "../box";
import Icon from "../icon";

interface ISelectBaseProps extends IStyledTextProps {
  value: string;
  variant?: "small" | "normal";
  onChange?: (e: OnSelectChangeEventType) => void;
}

const SelectBase = StyledUtil.styledAnyComponent(
  Box,
  {
    height: "100%",
    width: "100%",
    appearance: "none",
    display: "block",
    outline: "none",
    cursor: "pointer",
    border: 0,
  },
  variantFn({
    variants: {
      small: {
        height: Mixins.px2rem(Constants.INPUT_SMALL_HEIGHT),
        fontSize: 2,
      },
      normal: {
        height: Mixins.px2rem(Constants.INPUT_HEIGHT),
        fontSize: 3,
      },
    },
  }),
) as React.FC<ISelectBaseProps>;

interface IProps extends ISelectBaseProps {
  filterEnabled?: boolean;
  options: { value: string; label: string }[];
}

const Select: React.FC<IProps> = ({
  color,
  options,
  filterEnabled,
  bg,
  backgroundColor,
  ...props
}) => {
  return (
    <Flex
      alignItems="center"
      bg={bg || backgroundColor}
      {...(filterEnabled ? { paddingLeft: 2 } : {})}
    >
      {filterEnabled ? (
        <Icon name="filter" color={color} mr={1} volume="small" />
      ) : null}

      <SelectBase
        as="select"
        color={color}
        fontFamily="regular"
        fontSize={3}
        py={2}
        {...props}
        bg="transparent"
        {...(!filterEnabled ? { pl: 3 } : { pl: 2 })}
      >
        {options?.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </SelectBase>

      {!filterEnabled ? (
        <Icon name="arrowDown" color={color} volume="small" />
      ) : null}
    </Flex>
  );
};

Select.defaultProps = {
  filterEnabled: false,
  variant: "normal",
  onChange: () => {},
};

export default Select;
