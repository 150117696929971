export const toExcerpt = (body: string, limit: number = 400) => {
  if (!body) {
    return "";
  }

  const truncate = body?.substring(0, limit);
  const lines = truncate
    ?.replaceAll("\n", " ")
    .split(".")
    .reduce((acc: string[], line) => {
      if (!line) {
        return acc;
      }

      return [...acc, line];
    }, []);

  if (lines.length === 1) {
    return `${lines[0]}...`;
  }

  return `${lines.slice(0, lines.length - 1).join(".")}`;
};

export const toExcerptSEO = (body: string, limit: number = 200) => {
  return `${toExcerpt(body, limit).replaceAll("*", "")}.`;
};
