import * as React from "react";

import IconsBundle, { IIconProps } from "./iconBundle";

const Icon: React.FC<IIconProps> = ({ name, ...props }) => {
  const IconRaw = IconsBundle[name];
  if (!IconRaw) {
    return null;
  }

  return <IconRaw {...props} />;
};

export default Icon;
