import { IArticleGQL } from "@interfaces";
import Author from "./author";
import Category from "./category";
import Media from "./media";
import Tag from "./tag";

const Article = (props: IArticleGQL) => {
  const { id, attributes } = props;
  return {
    id,
    title: attributes?.title,
    slug: attributes?.slug,
    body: attributes.body,
    publishedAt: attributes.publishedAt,
    updatedAt: attributes.updatedAt,
    readingTime: attributes.readingTime,
    banner: Media(attributes.banner.data),
    category: Category(attributes?.category?.data),
    tags: attributes?.tags?.data?.map(Tag) ?? [],
    authors: attributes?.authors.data?.map(Author) ?? [],
  };
};

export type IArticle = ReturnType<typeof Article>;

export default Article;
