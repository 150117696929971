import { ImageType } from "@interfaces";

export const getFormatUrl = (
  image: ImageType,
  format: keyof ImageType["formats"] | "main",
  fallback: keyof ImageType["formats"] | "main" = "main",
) => {
  if (format === "main") {
    return image?.url;
  }

  const baseUrl = image?.formats[format]?.url;
  const fallbackUrl =
    fallback === "main" ? image.url : image?.formats[fallback]?.url;

  return baseUrl || fallbackUrl || image.url;
};

export const getImage = (
  image: ImageType,
  format: keyof ImageType["formats"] | "original",
  fallback: keyof ImageType["formats"] | "original" = "original",
): {
  url: string;
  width: number;
  height: number;
  name: string;
} => {
  const original = {
    url: image.url,
    width: image.width,
    height: image.height,
    name: image.name,
  };

  if (format === "original") {
    return original;
  }

  const imageFormat = image?.formats?.[format];
  const imageFallback =
    fallback === "original" ? original : image?.formats?.[fallback];

  if (imageFormat) {
    return {
      url: imageFormat.url,
      width: imageFormat.width,
      height: imageFormat.height,
      name: imageFormat.name,
    };
  }
  if (imageFallback) {
    return {
      url: imageFallback.url,
      width: imageFallback.width,
      height: imageFallback.height,
      name: imageFallback.name,
    };
  }

  return original;
};
