import Mixins from "./mixins";

export const INPUT_HEIGHT = "42px";
export const INPUT_SMALL_HEIGHT = "37px";

export const CONTAINER_PADDING_X = {
  sm: Mixins.px2rem("16px"),
  md: Mixins.px2rem("24px"),
  lg: Mixins.px2rem("64px"),
  xl: Mixins.px2rem("80px"),
  xxl: null,
  uhd: 0,
};

export const CONTAINER_PADDING_X_ARRAY = Object.values(CONTAINER_PADDING_X);

export const NAVBAR_HEIGHT = Mixins.px2rem("64px");

export const { NODE_ENV } = process.env;

const isDev = NODE_ENV === "development";

export const CACHE_KEYS = {
  HOME_CATEGORIES: "home_category_cache_exp",
  ABOUT: "about_expiration_query",
  RESOURCE: "resources_expiration_query",
  FOOTER: "footer_expiration_query",
  CATEGORIES: "cats_expiration_query",
};

export const CACHE_TIME = {
  SHORT: isDev ? 30 : 60 * 5, // 5 minute
  LONG: isDev ? 30 : 60 * 10, // 10 minutes
};
