import { gql } from "@apollo/client";
import {
  AUTHOR_FIELDS,
  CATEGORY_FIELDS,
  TAGS_FIELDS,
  ARTICLE_FIELDS,
  UPLOAD_FILE_FIELDS,
} from "./fragments";

export const GET_ARTICLES = gql`
  ${ARTICLE_FIELDS}
  query GET_ARTICLES(
    $filters: ArticleFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    articles(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        __typename
        attributes {
          ...ArticleFields
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

export const GET_ARTICLE = gql`
  ${ARTICLE_FIELDS}
  query GET_ARTICLES($id: ID!) {
    article(id: $id) {
      data {
        id
        __typename
        attributes {
          ...ArticleFields
        }
      }
    }
  }
`;

export const GET_ARTICLE_BY_SLUG = gql`
  ${ARTICLE_FIELDS}
  query GET_ARTICLE_BY_SLUG($slug: String!) {
    findSlug(modelName: "article", slug: $slug) {
      ... on ArticleEntityResponse {
        data {
          id
          attributes {
            ...ArticleFields
          }
        }
      }
    }
  }
`;

export const GET_TAGS = gql`
  ${TAGS_FIELDS}
  query GetTags {
    tags(sort: "publishedAt:desc") {
      data {
        id
        __typename
        attributes {
          ...TagFields
        }
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  ${CATEGORY_FIELDS}
  query GET_CATEGORIES {
    categories {
      data {
        id
        __typename
        attributes {
          ...CategoryFields
        }
      }
    }
  }
`;

export const GET_CATEGORIES_ARTICLES = gql`
  ${ARTICLE_FIELDS}
  query GET_CATEGORIES_ARTICLES($limit: Int) {
    categories {
      data {
        id
        attributes {
          __typename
          createdAt
          name
          articles(
            pagination: { limit: $limit }
            sort: ["title:asc", "publishedAt:desc"]
          ) {
            data {
              id
              __typename
              attributes {
                ...ArticleFields
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_AUTHOR = gql`
  ${AUTHOR_FIELDS}
  query GET_AUTHOR($id: ID!) {
    team(id: $id) {
      data {
        id
        __typename
        attributes {
          ...AuthorFields
        }
      }
    }
  }
`;

export const GET_AUTHOR_BY_SLUG = gql`
  ${AUTHOR_FIELDS}
  query GET_AUTHOR_BY_SLUG($slug: String!) {
    teamBySlug(slug: $slug) {
      data {
        id
        __typename
        attributes {
          ...AuthorFields
        }
      }
    }
  }
`;

export const GET_AUTHORS = gql`
  ${AUTHOR_FIELDS}
  query GET_AUTHORS {
    teams {
      data {
        id
        __typename
        attributes {
          ...AuthorFields
        }
      }
    }
  }
`;

export const GET_FOOTER = gql`
  query GET_FOOTER {
    footer {
      __typename
      data {
        id
        __typename
        attributes {
          __typename
          description
          socialNetworks {
            __typename
            id
            socialNetwork
            url
          }
        }
      }
    }
  }
`;

export const GET_ABOUT_PAGE = gql`
  ${AUTHOR_FIELDS}
  query GET_ABOUT_PAGE {
    about {
      __typename
      data {
        id
        __typename
        attributes {
          title
          description
          ourHistory
          createdAt
          ourTeamDescription
          team {
            id
            __typename
            directors {
              __typename
              data {
                id
                __typename
                attributes {
                  ...AuthorFields
                }
              }
            }
            editors {
              __typename
              data {
                id
                __typename
                attributes {
                  ...AuthorFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_RESOURCES = gql`
  ${UPLOAD_FILE_FIELDS}
  ${TAGS_FIELDS}
  query GET_RESOURCES {
    resources {
      __typename
      data {
        __typename
        id
        attributes {
          __typename
          title
          author
          link
          description
          tags {
            __typename
            data {
              id
              __typename
              attributes {
                ...TagFields
              }
            }
          }
          banner {
            __typename
            data {
              __typename
              id
              attributes {
                ...UploadFileFields
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;
